<template>
    <el-dialog
    title="添加位置设备"
    :visible.sync="dialogVisible"
    width="500px"
    append-to-body
    :before-close="handleAddClose">
    <div>
        <el-table ref="table" height="420px" :data="newLocationDeviceList"
         @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"/>
            <el-table-column label="设备位置" prop="idstName"/>
            <el-table-column label="添加状态" :filter-method="filterTag" :filters="[{text:'已添加',value:'已添加'},{text:'未添加',value:'未添加'}]">
                <!-- slot-scope="scope" -->
                <template slot-scope="scope">
                    <el-tag :type="scope.row.flag ==='未添加' ? 'success' : 'primary'">{{ scope.row.flag }}</el-tag>
                </template>
            </el-table-column>
        </el-table>
    </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="$emit('handleClose')">取 消</el-button>
    <el-button type="primary" @click="handleAddClose('确定')">提 交</el-button>
  </span>
</el-dialog>
</template>


<script>
import { mapState } from 'vuex'
import { findObj } from '@/utils/utils'

export default {
    props:{
        dialogVisible:Boolean
    },
    data(){
        return {
            newLocationDeviceList:null,
            selects:null
        }
    },
    computed:{
        ...mapState({
            locationDeviceList:state => state.contextualModelState.locationDeviceList,
        }),
    },
    watch:{
        "locationDeviceList":{
            handler(newVal){
                console.log(newVal);
                this.newLocationDeviceList = JSON.parse(JSON.stringify(newVal))            
            }
        }
    },
    mounted(){
        this.newLocationDeviceList = JSON.parse(JSON.stringify(this.locationDeviceList))
    },
    methods:{
        filterTag(value,row){
           return row.flag == value;
        },
        handleSelectionChange(val){
            this.selects = val
        },
        // 父组件触发的方法
        changeState(row){
            const index = findObj(this.newLocationDeviceList,row)
            this.newLocationDeviceList[index].flag = '未添加'
            this.$refs.table.toggleRowSelection(this.newLocationDeviceList[index],false)
        },
        handleAddClose(flag){
            if(flag === '确定'){
                if(this.selects.length === 0){
                    this.newLocationDeviceList.forEach(item => {
                        item.flag = '未添加'
                    });
                    console.log(this.newLocationDeviceList)
                    this.$emit('handleClose',this.selects)
                    this.$message({
                        type:'success',
                        message:'添加位置设备成功'
                    })
                    return
                }
                this.newLocationDeviceList.map(item => {
                    item.flag = '未添加'
                    this.selects.map(row => {
                        if(row.idstId === item.idstId){
                            item.flag = '已添加'
                        }
                    })
                })
            }
            this.$emit('handleClose',this.selects)
        }
    }
}
</script>
