<template>
    <div>
        <el-form align="center">
            <el-row>
                <el-col :span="8">
                    <el-form-item label="场景位置：" label-width="100px" prop="affiliation" >
                        <el-input v-model="forms.affiliation"  @click.native="seleceRoom"  readonly placeholder="点击选择场景归属位置" size="mini" ></el-input> 
                        <el-dialog v-dialogDrag title="选择其他房间" :visible.sync="installationAddress" :close-on-click-modal="false" @close="installationAddressClose" width="800px" append-to-body>
                            <InstallationAddress ref="installationAddress" @rowDB="confirmSelece" v-if="installationAddress" :isDuoXuan="isDuoXuan=false"/>
                        </el-dialog>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div v-if="forms.hsId">
            <el-table
              v-loading="loading"
              :data="tableData"
              row-key="address"
              border
              style="width: 100%"
              height="400px"
              ref="tableList">
              <el-table-column show-overflow-tooltip prop="jsOrderId" label="序号" width="50" align="center"></el-table-column>
              <el-table-column show-overflow-tooltip aligen="center"  label="场景位置">
                <template slot-scope="scope">
                    {{ scope.row.hsAddCommunity }} {{ scope.row.hsAddDoorplateno }} {{ scope.row.hsAddFloor }}
                </template>
              </el-table-column>
              <el-table-column show-overflow-tooltip prop="jscName" label="场景模式" aligen="center"></el-table-column>
              <el-table-column label="操作" align="center" width='150px'>
                <template slot-scope="scope">
                     <el-tooltip class="item" effect="dark" content="置顶" placement="left">
                      <ToTop theme="filled" size="30" fill="#409EFF" @click="baocun(scope)" class="totop"/>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="收藏" placement="right">
                      <Like :theme="scope.row.jsCollect?'filled':'outline'" size="30" fill="#409EFF" @click="collectionStop(scope,scope.$index)" class="collection"/>
                    </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <el-row style="margin-top:24px">
                <el-col :span="24" align="right">
                    <el-button type="primary" @click="submitForm">保存</el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import InstallationAddress from '@/components/equipmentChild/installationAddress.vue'
import { sceneList,sceneUpdateOrderID } from '@/api/scene.js'
import Sortable from 'sortablejs';
import { ToTop,Like } from '@icon-park/vue'

export default {
    data() {
        return {
          installationAddress:false,
          roomData:{},
          forms:{
              affiliation:'',
              hsId:'',
          },
          tableData: [],
          loading:false
        }
    },
    mounted() {
    },
    components:{
        InstallationAddress,
        ToTop,
        Like
    },
    methods: {
      //收藏
      collectionStop(scope,index){
        console.log("收藏",scope.row.jsCollect)
        this.tableData[index].jsCollect = !this.tableData[index].jsCollect==false?0:1
      },
      //置顶
      baocun(scope){
        let values = this.tableData;//这个是表格数据
	      let value = this.tableData[scope.$index];
	      let res = [value].concat(values.filter(item => item != value));//concat()方法用于连接两个或多个数组
	      console.log(values.filter(item => item != value));
	      this.tableData = res;
      },
      //保存
      submitForm(){
        this.loading=true
        let result=[]
        for(var i=0;i<this.tableData.length;i++){
          let obj={}
          obj.jsId=this.tableData[i].jsId
          obj.jsHouseId=this.tableData[i].jsHouseId
          obj.jsOrderId=i+1
          obj.jsCollect=this.tableData[i].jsCollect
          result.push(obj)
        }
        console.log("result",result)
          sceneUpdateOrderID({
            result:result
          }).then(res=>{
            // console.log("res:",res)
            this.$message.success("保存成功")
            this.getTableList()
          }).catch(err=>{
            this.isError(err,this)
          })
      },
      // 拖拽排序
      rowDrop() {
          this.$nextTick(()=>{
            const tbody = this.$refs.tableList.$el.querySelector(".el-table__body-wrapper tbody");
            console.log("tbody",tbody);
            console.log(tbody.childNodes)
            let that = this
            Sortable.create(tbody,{
              animation: 500,
              draggable:'.el-table__row',
              onEnd({ newIndex, oldIndex }) {
                // 修改data中的数组，
                const targetRow = that.tableData.splice(oldIndex, 1)[0]
                that.tableData.splice(newIndex, 0, targetRow)
                let newArray = that.tableData.splice(0);
                that.tableData = [];
                that.$nextTick(function () {
                  that.tableData = newArray;
                });
              }
            });
          })
            
            // const that = this;
      },
      installationAddressClose(){
          this.roomData = {}
      },
      // getMsgFormSon(data){
      //     console.log(data);
      //     this.roomData = data
          
      // },
      seleceRoom(){
        this.installationAddress = true;
      },
      confirmSelece(data){
        this.loading=true
        console.log("data",this.tableData)
        this.installationAddress = false;
        this.forms.affiliation = data.hsAddCommunity
        this.forms.hsId = data.hsId
        this.getTableList()
        this.rowDrop();
      },
      getTableList(){
        sceneList({
          hsId:this.forms.hsId,
          isByUser:false,
        }).then(res=>{
          this.tableData = res.data.result
          // for (let i = 0; i < this.tableData.length; i++) {
          //   this.tableData[i].jsCollect
          // }
          console.log("table",this.tableData);
          this.loading=false
        }).catch(err=>{
          this.isError(err,this)
          this.loading=false
        })
      },
    },
}
</script>

<style scoped>
.totop{
  height:100%;
  cursor: pointer;
}
.collection{
  margin-left: 20px;
  cursor: pointer;
}
</style>