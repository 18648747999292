<template>
  <div class="box">
      <el-form :inline="true">
        <div>
          <el-form-item label="电视：" label-width="70px">
              <el-select v-model="row.tv" clearable size="small" style="width: 100px" placeholder="未设置">
                  <el-option v-for="item in cities" :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="空调：" label-width="70px">
              <el-select v-model="row.airConditioning" clearable size="small" style="width: 100px" placeholder="未设置">
                  <el-option v-for="item in cities" :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
              </el-select>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="红外：" label-width="70px">
              <el-select v-model="row.infraCed" clearable size="small" style="width: 100px" placeholder="未设置">
                  <el-option v-for="item in cities" :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="投影仪：" label-width="70px">
              <el-select v-model="row.projector" clearable size="small" style="width: 100px" placeholder="未设置">
                  <el-option v-for="item in cities" :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
              </el-select>
          </el-form-item>
        </div>
      </el-form>
  </div>
</template>

<script>
import { controllDeviceItem } from '@/api/scene.js'
const cityOptions = [{label:'开',value:true},{label:'关',value:false}];
export default {
    props:{
      row:Object,
    },
    data() {
        return {
          radio1: '',
          cities: cityOptions
        }
    },
    created() {
      console.log(this.row)
    },
    methods: {
        // sliderChange(){
        //     this.$emit('chamberlainScene',this.row)
        // },

    },
}

</script>

<style lang="scss" scoped>

</style>