<template>
    <div>
        <el-form :inline="true">
            <el-form-item label="温度：" label-width="50px">
                <el-input v-model="row.temperature" v-if="row.devSecondType != 141" clearable size="small" placeholder="请输入15到35之间的数字" ></el-input>
                <el-input v-else v-model="row.temperature" clearable size="small" placeholder="请输入15到30之间的数字" ></el-input>
            </el-form-item>
            <el-form-item label="模式：" label-width="50px">
                  <el-select v-model="row.pattern" clearable size="small" style="width: 100%">
                      <el-option label="自动" value="自动" v-if=" row.devSecondType != 137 && row.devSecondType != 141"></el-option>
                      <el-option label="制冷" value="制冷"></el-option>
                      <el-option label="制热" value="制热"></el-option>
                      <el-option label="送风" value="送风"></el-option>
                      <el-option label="除湿" value="除湿" v-if="row.devSecondType != 137"></el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="风速：" label-width="50px">
                <el-select v-model="row.windSpeed" clearable size="small" style="width: 100%">
                      <el-option label="自动" value="自动" v-if="row.devSecondType != 141"></el-option>
                      <el-option label="高" value="高"></el-option>
                      <el-option label="中" value="中"></el-option>
                      <el-option label="低" value="低"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
  props: {
    row: Object,
  },
  created() {
      console.log(this.row)
  },
};
</script>

<style></style>
