<template>
  <div v-loading.fullscreen="loaded">
    <el-form :model="addeq" :rules="addrules" ref="addrules" inline>
        <el-form-item label="情景信息" prop="scene">
          <el-input style="width:60vw;" :autosize="{ minRows: 2, maxRows: 5}" type="textarea" v-model="addeq.scene"></el-input>
        </el-form-item>
        <el-button type="primary" @click="addScene">添加情景</el-button>
    </el-form>
    <!-- <json-viewer
        :value="jsonData"
        :expand-depth=5
        copyable
        boxed
        sort>
    </json-viewer> -->
  </div>
</template>

<script>
export default {
    data() {
        return {
            loaded:false,
            jsonData:'',
            addeq:{
                scene:''
            },
            addrules: {
              scene: [
                {
                  required: true,
                  message: "请输入情景数据",
                  trigger: "blur,change",
                //   pattern: /\w{14,}/,
                },
              ],
            },
        }
    },
methods:{
    loading() {
      this.loaded = true
    },
    finish() {
      this.loaded = false
    },
    addScene(){
        this.loading()
        this.$refs.addrules.validate((valid) => {
            // console.log(this.addeq.scene.replace(/\s+/,''))
            if (valid) {
              this.$axios({
                url: "/common2/scene/addByJSON",
                method: "post",
                data:this.addeq.scene,
              }).then(res => {
                this.$emit('shuaxinData')
                this.$message.success('导入成功')
                this.finish()
              }).catch(err => {
                    this.finish()
                    this.isError(err, this)
              });
            }else{
              return false;
            }
        });
    }
}
}
</script>
<style lang="scss" scoped>
.el-textarea{
/deep/ .el-textarea__inner{
    min-height:50vh
}
} 
</style>