<template>
  <div>
    <CoolingAndHeatingLamp @coolingAndHeatingLamp='control'  v-if="equipmentInfo.coolingAndHeatingLamp.secondType.indexOf(row.devSecondType)>-1" :row="row"/>
    <AirConditioner @airConditioner='control'  v-else-if="equipmentInfo.airConditioner.secondType.indexOf(row.devSecondType)>-1" :row="row"/>
    <ChamberlainScene @chamberlainScene='control'  v-else-if="equipmentInfo.chamberlainScene.secondType.indexOf(row.devSecondType)>-1" :row="row"/>
    <el-form v-else>
      <el-row>
        <el-col align="center">
          没有相关操作
        </el-col>
      </el-row>
    </el-form>
     <!-- v-if="coolingAndHeatingLamp.secondType.indexOf(panelForm.devSecondType) > -1" -->
  </div>
</template>
<script>
import CoolingAndHeatingLamp from './detail/coolingAndHeatingLamp.vue'
import AirConditioner from './detail/airConditioner.vue'
import ChamberlainScene from './detail/chamberlainScene.vue'
import { equipmentInfo } from '@/views/equipmentMatters/contextualModel-cpns/batchAdditionDialog/local-host.js'
import { controllDeviceItem } from '@/api/scene.js'

export default {
  props:{
    row:Object||Array,
    isRealControl:Boolean,
  },
  components:{
    CoolingAndHeatingLamp,
    AirConditioner,
    ChamberlainScene
  },
  data() {
      return {
        equipmentInfo,
        panelForm:{},
      }
  },
  created() {
    console.log(this.row)
  },
  methods:{
    control(row){
      if (!this.isRealControl) {return}
      if(equipmentInfo.coolingAndHeatingLamp.secondType.indexOf(row.devSecondType)>-1){
        this.coolingAndHeatingLamp(row)
      }else if(equipmentInfo.airConditioner.secondType.indexOf(row.devSecondType)>-1){
        this.airConditioner()
      }
    },
    coolingAndHeatingLamp(row){
      controllDeviceItem({devState:(row.state ? "开" :"关"),devId:row.jourDeviceId,brightness:row.brightness,colorTemperature:row.colorTemperature}).then((res)=>{
          this.$message.success('控制成功')
      }).catch((err)=>{
          this.isError(err,this)
      })
    },
    airConditioner(){

    }
  },
}
</script>

<style>

</style>