<template>
  <div>
    <el-table
      :data="scenarioEditor.devices"
      style="width:100%;"
      height="360px"
      v-loading="scenarioEditor.loading"
    >
      <el-table-column width="55"></el-table-column>
      <el-table-column
        prop="devNickname"
        label="设备列表"
        min-width="100px"
      ></el-table-column>
      <el-table-column label="是否启用" min-width="100px">
        <template>
          <el-switch
            :active-value="1"
            :inactive-value="0"
            active-text="启用"
            inactive-text="不启用"
            class="switchStyle"
            active-color="#00A854"
            inactive-color="#F04134"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="开关状态" min-width="100px">
        <template>
          <el-switch
            active-value="开"
            inactive-value="关"
            active-text="开"
            inactive-text="关"
            class="switchStyle"
            active-color="#00A854"
            inactive-color="#F04134"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="详细设置" min-width="100px">
        <template>
          <el-button size="small" type="warning">详细设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button
      type="primary"
      @click="scenarioEditor.dialogVisible = false"
      style="margin-top:10px;float:right"
      >提交</el-button
    >
  </div>
</template>
<script>
export default {
  props: {
    scenarioEditor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
