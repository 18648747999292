<template>
  <div>
      <el-form>
        <el-form-item label="亮度：" label-width="50px">
                <el-slider
                  v-model="row.brightness"
                  :min="1"
                  :max="100"
                  @change="sliderChange"
                ></el-slider>
        </el-form-item>
        <el-form-item label="色温："  label-width="50px">
              <el-slider
                v-model="row.colorTemperature"
                :min="1"
                :max="100"
                @change="sliderChange"
              ></el-slider>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
export default {
    props:{
      row:Object,
    },
    data() {
        return {
        }
    },
    // computed:{
    //   brightness:{
    //     get:function(){
    //       return Number(this.row.brightness);
    //     },
    //     set:function(val){
    //       this.row.brightness = val
    //     }
    //   },
    //   colorTemperature:{
    //     get:function(){
    //       return Number(this.row.colorTemperature);
    //     },
    //     set:function(val){
    //       this.row.colorTemperature = val
    //     }
    //   }
    // },
    created() {
      console.log(this.row)
    },
    methods: {
        sliderChange(){
            this.$emit('coolingAndHeatingLamp',this.row)
        },

    },
}

</script>

<style>

</style>