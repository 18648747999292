<template>
  <div class="el-card-define">
    <el-card class="box-card">
      <div slot="header" class="clearfix space-between">
        <span v-if="step === 0" class="center">步骤1</span>
        <span v-if="step > 0" class="center">
          当执行情景步骤{{ step }}后延时
          <el-input-number size="mini" v-model="delayExecutionTime" :min="1" :max="60" />
          秒后执行步骤{{ step + 1 }}
        </span>
        <div class="center fz">
          <el-button icon="el-icon-plus" type="primary" size="mini" @click="addDeviceBtn">添加位置设备</el-button>
          <el-button @click="batchSettingDetail" type="primary" icon="el-icon-setting" size="mini">
            批量设置
          </el-button>
          <el-button @click="delNodeCard(step)" v-show="step > 0" type="danger" icon="el-icon-delete" size="mini">
            删除此节点
          </el-button>
        </div>
      </div>
      <el-table :data="addDeviceList" v-loading="loading1" empty-text="您还没添加设备呢" height="200px">
        <el-table-column label="设备位置" prop="idstName"></el-table-column>
        <el-table-column label="设备开关" prop="state" :render-header="renderHeader">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.state" active-color="#13ce66" inactive-color="#ff4949"> </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="设备详细">
          <template slot-scope="scope">
            <div class="fz">
              <el-button
                :disabled="!scope.row.state"
                v-if="scope.row.setStatus"
                size="mini"
                type="warning"
                @click="setting(scope.row.devSecondType, scope.row.setStatus)"
              >
                详细设置
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="设备操作" class="fz">
          <template slot-scope="scope">
            <div class="fz">
              <el-button @click="removeDevice(scope.row)" type="danger" icon="el-icon-delete" size="mini">
                移除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 批量设置弹窗开始 -->
      <el-dialog
        title="批量设置"
        :visible.sync="settingBtnDialogVisible"
        append-to-body
        :before-close="handelSettingBtnDialogVisible"
      >
        <el-button
          type="primary"
          size="mini"
          v-for="(item, index) in btnArr"
          @click="itemBatchSetting(item.text)"
          :key="index"
          >{{ item.text }}批量设置</el-button
        >
      </el-dialog>
      <!-- 批量设置弹窗结束 -->
      <AddLocationDevice
        ref="addLocationDevice"
        :dialogVisible="addDeviceDialogVisible"
        @handleClose="handleAddClose"
      />
      <SettingDialog
        :setStatus="setStatus"
        :settingArr="settingArr"
        :dialogVisible="settingDialogVisible"
        @handleSettingClose="handleSettingClose"
      />
    </el-card>
  </div>
</template>
<script>
import AddLocationDevice from "./addLocationDevice";
import SettingDialog from "./settingDialog";
import { equipmentInfo } from "./local-host";
import { deWeightThree } from "@/utils/utils";
import { mapState } from "vuex";
export default {
  props: {
    nextFlag: Boolean,
    step: Number,
    total: Number,
  },
  data() {
    return {
      addDeviceList: [],
      addDeviceDialogVisible: false,
      delayExecutionTime: 1,
      settingDialogVisible: false,
      settingBtnDialogVisible: false,
      settingArr: "",
      setStatus: {},
      btnArr: [],
    };
  },
  components: {
    AddLocationDevice,
    SettingDialog,
  },
  computed: {
    ...mapState({
      loading1: (state) => state.contextualModelState.loading1,
    }),
  },
  methods: {
    renderHeader(h, { column }) {
      console.log(h, column);
      return h("div", [
        h("span", column.label),
        h("el-checkbox", {
          style: "margin-left:5px;",
          on: {
            change: ($event, column) => this.addDeviceList.forEach(item => {
                item.state = $event
            }), // 选中事件 $event, column，这里$event=true,column是input的dom当在select里打印的时候
          },
        }),
      ]);
    },
    handelSettingBtnDialogVisible() {
      this.settingBtnDialogVisible = false;
    },
    delNodeCard(step) {
      this.$emit("changeNodeCard", { index: step });
    },
    addDeviceBtn() {
      console.log(this.nextFlag);
      this.$emit("handleNext");
      this.$nextTick(() => {
        if (!this.nextFlag) {
          this.$message({
            type: "warning",
            message: "请选择场景类别",
          });
          return;
        }
        this.addDeviceDialogVisible = true;
      });
    },
    removeDevice(row) {
      const index = this.addDeviceList.indexOf(row);
      this.addDeviceList.splice(index, 1);
      // 触发添加弹窗修改状态的方法
      this.$refs.addLocationDevice.changeState(row);
      this.$message({
        type: "warning",
        message: "移除位置设备成功",
      });
    },
    handleAddClose(playload) {
      if (playload) {
        this.addDeviceList = playload;
      }
      this.addDeviceDialogVisible = false;
    },
    handleSettingClose(flag) {
      this.settingDialogVisible = false;
      if (this.settingArr === "冷暖灯") {
        this.addDeviceList.map((item) => {
          console.log(item);
          if (equipmentInfo.coolingAndHeatingLamp.secondType.indexOf(item.devSecondType) > -1 && item.state) {
            item.setStatus = this.setStatus;
          }
        });
      }
    },
    setting(devSecondType, setStatus) {
      let arr = [];
      arr.push(devSecondType);
      this.settingArr = arr;
      this.settingDialogVisible = true;
      this.setStatus = setStatus;
      console.log(this.settingArr);
    },
    batchSettingDetail() {
      this.addDeviceList.map((item) => {
        this.outputBtnObj(item);
      });
      if (this.btnArr.length === 0) {
        this.$message({
          type: "warning",
          message: "暂无可批量设置的设备",
        });
        return;
      }
      this.btnArr = deWeightThree(this.btnArr);
      this.settingBtnDialogVisible = true;
    },
    itemBatchSetting(text) {
      this.settingArr = text;
      if (text === "冷暖灯") {
        this.setStatus = {
          brightness: 1,
          colorTemperature: 1,
        };
      }
      this.settingDialogVisible = true;
    },
    outputBtnObj(item) {
      let obj = null;
      // 空调
      if (equipmentInfo.airConditioner.secondType.indexOf(item.devSecondType) > -1 && item.state) {
        obj = {
          text: "空调",
        };
        this.btnArr.push(obj);
        return;
      }
      // 冷暖灯
      if (equipmentInfo.coolingAndHeatingLamp.secondType.indexOf(item.devSecondType) > -1 && item.state) {
        obj = {
          text: "冷暖灯",
        };
        this.btnArr.push(obj);
        return;
      }
      // 调节灯
      if (equipmentInfo.adjustingLamp.secondType.indexOf(item.devSecondType) > -1 && item.state) {
        obj = {
          text: "调节灯",
        };
        this.btnArr.push(obj);
        return;
      }
      // 地暖
      if (equipmentInfo.underfloorHeatingLamp.secondType.indexOf(item.devSecondType) > -1 && item.state) {
        obj = {
          text: "地暖",
        };
        this.btnArr.push(obj);
        return;
      }
      // 红外
      if (equipmentInfo.lnfraredAlarm.secondType.indexOf(item.devSecondType) > -1 && item.state) {
        obj = {
          text: "红外",
        };
        this.btnArr.push(obj);
        return;
      }
      //窗帘
      if (equipmentInfo.percentageCurtain.secondType.indexOf(item.devSecondType) > -1 && item.state) {
        obj = {
          text: "窗帘",
        };
        this.btnArr.push(obj);
        return;
      }
    },
  },
};
</script>

<style scoped>
.el-card-define >>> .el-card__body {
  height: 300px;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fz >>> .el-button {
  font-size: 12px !important;
}
.space-between {
  display: flex;
  justify-content: space-between;
}
</style>
