<template>
    <el-dialog
    title="批量添加场景"
    :visible.sync="dialogVisible"
    width="900px"
    :before-close="handleClose">
    <div>
        <el-steps :active="active" finish-status="success">
            <el-step title="选择场景位置"></el-step>
            <el-step title="设置场景"></el-step>
        </el-steps>
        <div v-show="active == 0"  >
            <el-form label-width="70px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="城区">
                            <el-select style="width:160px" clearable @change="changeParams1" size="mini" v-model="params.hsAddDistrict">
                                <el-option v-for="(item,index) in cityList" :key="index" :label="item.hdDistrict" :value="item.hdDistrict"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                <el-col :span="8">
                    <el-form-item label="房间名称">
                        <el-input style="width:160px" @change="changeParams" size="mini" v-model="params.hsAddCommunity"/>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="门牌搜索">
                        <el-input style="width:160px" @change="changeParams" size="mini" v-model="params.hsAddDoorplateno"/>                    
                    </el-form-item>
                </el-col>
            </el-row>
            </el-form>
            <!-- 
                :row-key="Number(Math.random().toString().substr(3,length) + Date.now()).toString(36)"
             -->
            <el-table ref="table"  
            @selection-change="handleSelectionChange"
            row-key="hsId" 
            :data="dataList" height="350" v-loading="loading">
                <el-table-column type="selection" :reserve-selection="true"/>
                <el-table-column label="城区" prop="hsAddDistrict"/>
                <el-table-column label="门店" prop="hsAddCommunity"/>
                <el-table-column label="房型" prop="hsRoomType"/>
                <el-table-column label="楼层" prop="hsAddFloor"/>
                <el-table-column label="门牌" prop="hsAddDoorplateno"/>
            </el-table>
            <div class="center">
                <el-pagination
                background
                @current-change="changePage"
                :page-size="params.limit"
                layout="prev, pager, next"
                :total="total" />
            </div>
        </div>
        <div v-show="active == 1">
            <el-form ref="form" :model="sceneList">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label-width="80px" label="场景类别:" prop="category" :rules="[{ required: true, message: '请选择场景类别', trigger: ['blur','change'] }]">
                            <el-select clearable size="mini" v-model="sceneList.category" @change="getPositionInfo">
                                <el-option v-for="item in sceneCategoryList"  :key="item.jscId" :label="item.jscName" :value="item.jscId"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label-width="140px" label="支持离线执行情景:">
                            <el-switch
                                @change="changeExecutionMode"
                                v-model="isOffLine"
                                active-color="#13ce66"
                                inactive-color="#ff4949"/>
                            </el-form-item>
                    </el-col>
                     <el-col v-show="!isOffLine" :span="8">
                       <el-form-item class="fz">
                            <el-button @click="addNodeCard" type="primary" size="mini" icon="el-icon-plus">添加场景节点</el-button>
                       </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <el-carousel style="margin-top:30px" 
            :autoplay="false"
            indicator-position="none"
            ref="carousel"
            arrow="never">
                <el-carousel-item v-for="(item,index) in totalCard" :key="item.key">
                    <NodeCard 
                    :total="totalCard.length" 
                    :step="index" ref="nodeCard" 
                    :nextFlag="nextFlag" 
                    @handleNext="handleNext"
                    @changeNodeCard="changeNodeCard"
                    />
                </el-carousel-item>
            </el-carousel>
            <div class="center">
                <el-pagination
                    @current-change="currentChange"
                    background
                    :current-page="currentPage"
                    :page-size="1"
                    layout="prev, pager, next"
                    :total="totalCard.length">
                </el-pagination>
            </div>
        </div>  
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button @click="lastStep" v-if="active != 0">上一步</el-button>
        <el-button type="primary" @click="clickNextBtn">{{ nextBtnText }}</el-button>
    </span>
    </el-dialog>
</template>

<script>
import { getHouse4store } from '@/api/equipmentList'
import { getAllSceneCategory,saveScenes } from '@/api/scene'
import NodeCard from './nodeCard'
import { mapState } from 'vuex'

export default {
    props:{
        dialogVisible:Boolean,
    },
    components:{
        NodeCard
    },
    data(){
        return {
            loading:false,
            active:0,
            nextBtnText:'下一步',
            dataList:[],
            total:null,
            params:{
                limit:99,
                cursor:1,
                hsAddCommunity:'',
                hsAddDistrict:'',
                hsAddDoorplateno:''
            },
            // 总选中数据
            storageSelectData:[],
            noSelectData:[],
            sceneList:{
                category:'',
                position:''
            },
            sceneCategoryList:null,
            scenePositionList:null,
            isOffLine:false,
            nextFlag:false,
            // nodeCard 的数量
            totalCard:[{ key:1 }],
            currentPage:1,
            hsIds:[]
        }
    },
    computed:{
        ...mapState(['cityList']),
    },
    watch:{
        "active":{
            handler(newVal){
                if(newVal === 0){
                    this.nextBtnText = '下一步'
                }
            }
        },
        "totalCard.length":{
            handler(newVal){
                console.log(newVal);
                this.$nextTick(() => {
                    this.currentChange(newVal)
                })
            }
        }
    },
    methods:{
        changeNodeCard(playload){
            this.totalCard.splice(playload.index,1)
        },
        changeExecutionMode(val){
            if(val && this.totalCard.length === 1){
                this.$confirm('如果支持离线模式您将不能添加情景节点', '温馨提示', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确认',
                    cancelButtonText: '取消'
                }).catch(() => {
                    this.isOffLine = false
                })
                return
            }
            if(val){
                this.$confirm('如果支持离线模式您之前所创建的情景节点都将被删除只保留步骤1', '温馨提示', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '确认',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.totalCard.splice(1,this.totalCard.length - 1)
                }).catch(() => {
                    this.isOffLine = false
                })
            }
        },
        currentChange(val){
            this.$refs.carousel.setActiveItem(val - 1)
            this.currentPage = this.totalCard.length
        },
        addNodeCard(){
            this.$refs['form'].validate(value => {
                if(!value){
                    this.$message({
                        type:'warning',
                        message:'请先选择场景类别',
                        showClose:true
                    })
                    return false
                }
                 if(this.$refs['nodeCard'][this.totalCard.length - 1].addDeviceList.length === 0){
                        this.$message({
                            type:'warning',
                            message:`请先往步骤${this.totalCard.length}添加设备`,
                            showClose:true
                        })
                        return
                    }
                const obj = {}
                obj.key = this.totalCard[this.totalCard.length -1].key + 1
                this.totalCard.push(obj)
                this.$nextTick(() => {
                    console.log('执行');
                    this.currentPage = this.totalCard.length
                })
                this.$message({
                    message:'添加情景节点成功!',
                    type:'success',
                    showClose: true
                }) 
            })
        },
        handleNext(){
            this.$refs['form'].validate(value => {
                console.log(value);
                if(value){
                    this.nextFlag = true
                }else{
                    this.nextFlag = false
                    return false
                }
            })
        },
        getPositionInfo(){
            this.hsIds = this.storageSelectData.map(item => item.hsId)
            this.$store.dispatch('getSelectDevices',{ hsIds:this.hsIds })
        },
        handleSelectionChange(val){
            this.storageSelectData = val
        },
        handleClose(){
            this.$emit('handleBatchDialogClose')
        },
        changeParams1(){
            this.getSceneInfo()
        },
        changeParams(){
            this.getSceneInfo()
        },
        clickNextBtn(){
            if(this.storageSelectData.length === 0){
                this.$message({
                    type:'warning',
                    message:'请选择场景位置'
                })
                return
            }
            if(this.active++ >= 1){
                this.active = 2
            }
            if(this.active == 1 || this.active == 2){
                this.nextBtnText = '确定'
            }
            if(this.active === 2){
                console.log('触发');
                this.$refs.form.validate(value => {
                    if(value){
                        const data = {
                            sceneCategoryId: this.sceneList.category,
                            hsIds: this.hsIds,
                            isOffline: this.isOffLine ? 1 : 0,
                            currentState:0,
                            info:[]
                        }
                        this.$refs['nodeCard'].forEach((item,index) => {
                            if(item.addDeviceList.length === 0){
                                this.$message({
                                    type:'warning',
                                    message:`请前往步骤${index + 1}添加设备`
                                })
                                this.active = 1
                                throw new Error(`请前往步骤${index + 1}添加设备`)
                            }
                            let infoItem = {
                                delay:item.delayExecutionTime,
                                instruction:[]
                            }
                            item.addDeviceList.map(row => {    
                                let instructionItem = {
                                    idstId:row.idstId,
                                    setStatus:row.setStatus,
                                    state:row.state
                                }
                                infoItem.instruction.push(instructionItem)
                            })
                            data.info.push(infoItem)
                        })
                        // console.log(data)
                        saveScenes(data).then(res => {
                            this.$message({
                                type:'success',
                                message:'添加情景节点'
                            })
                            this.handleClose()
                        }).catch(err => {
                            this.active = 1
                            console.log(err.response)
                            this.$message({
                                type:'error',
                                message:err.response.data.msg
                            })
                        })
                    }else{
                        this.active = 1
                        return
                    }
                })
            }
        },
        lastStep(){
           this.sceneList.category = ''
           this.$nextTick(() => {
               this.$refs['form'].clearValidate('category')
           })
           this.nextBtnText = '下一步'
           if(this.active-- == 0){
               this.active = 0
           }
        },
        changePage(val){
            console.log(val);
            this.params.cursor = val
            this.getSceneInfo()           
        },
        getSceneInfo(flag){
            this.loading = true
            if(flag){
                this.active = 0
                this.sceneList.category = ''
                this.storageSelectData = []
                this.$nextTick(() => {
                    this.$refs['table'].clearSelection()
                })
                Object.assign(this.params,this.$options.data().params)
                getAllSceneCategory().then(res => {
                    this.sceneCategoryList = res.data.result
                })
            }
            getHouse4store(this.params).then(res => {
                this.loading = false
                this.total = res.data.result.total
                this.dataList = res.data.result.records
            })
        }
    }
}
</script>

<style scoped>
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.fz >>> .el-button{
    font-size: 12px !important;
}
</style>