<template>
  <div>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <div style="margin:-18px -20px; padding:10px 20px; ">
          <span v-if="trottingHorseLamp.current > 1">当执行情景步骤{{ trottingHorseLamp.current - 1 }}后</span>
          <span class="timer-describe" v-if="trottingHorseLamp.current !==1"> 延时</span>
          <el-input-number v-if="trottingHorseLamp.current !==1" v-model="cardDatas.jsiDelay" :min="1" :max="60" size="mini"  ></el-input-number>
          <span v-if="trottingHorseLamp.current !==1"> 秒后执行步骤{{ trottingHorseLamp.current }} </span>
          <span v-if="trottingHorseLamp.current ==1"> 步骤{{ trottingHorseLamp.current }} </span>
          <div class="deletePage">
            <el-popconfirm v-if="trottingHorseLamp.current!==1" title="请确定删除此步骤？" @confirm="deleteCurrentPage">
              <el-button slot="reference" type="danger" icon="el-icon-delete" circle ></el-button>
            </el-popconfirm>
          </div>
          <div class="buttonBox">
            <el-button type="primary" size="mini" class="buttonStyle" @click="callOnlineExecution">在线执行</el-button>
            <el-button type="primary" size="mini" class="buttonStyle" @click="batchsSet">批量设置</el-button>
            <el-button type="primary" size="mini" class="buttonStyle" @click="addDeviceIntoScene" icon="el-icon-search">添加设备</el-button>
          </div>
          <el-dialog title="添加设备" :visible.sync="standSceneListData.dialogVisible" width="70%" @close="closePageInputVale" append-to-body>
            <addDeviceIntoList ref="devList" @func="getSelectedDev" :standSceneListData="standSceneListData" :test="'执行设备'" ></addDeviceIntoList>
          </el-dialog>
          <el-dialog title="选择批量设备" :visible.sync="batchsSetDialog" width="500px" :close-on-click-modal="false" @close="batchsSetClose" append-to-body > 
            <div v-if="batchsSetDialog">
              <el-button v-for="(item, index) in batchsSetButton" :key="index" type="primary" size="mini" @click="batchsSetDetail($event,item)">{{item.buttonName}}</el-button>
            </div>
              <!-- 详细设置 -->
            <el-dialog title="详细设置"  :class="controlBox.secondType.indexOf(panelForm.devSecondType) > -1?'controlObjSttingBox':''" :visible.sync="dialogCaoZuoSheBei" width="550px" :close-on-click-modal="false" append-to-body>
              <EquipmentList ref="EquipmentList" @closeEquipmentList="getEquipmentOperation" v-if="dialogCaoZuoSheBei" :isDialogCaoZuoSheBei="dialogCaoZuoSheBei" :isDevSecondType="panelForm.devSecondType" :isDevFirstType="panelForm.devFirstType" :panelFormRow="panelForm.row"></EquipmentList>
            </el-dialog>
          </el-dialog>
        </div>
      </div>
      <div style="margin:-20px -20px;">
        <el-table ref="devTable" style="width: 100%" height="300px" :data="selectedDev" >
          <el-table-column align="left" label="设备信息" width="300" show-overflow-tooltip >
            <template slot-scope="scope">
              <div>
                <span>设备名称：{{scope.row.devNickname}}</span><br/>
                <span>设备SN：{{scope.row.devSn}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备开关" :render-header="renderHeader" width="200" >
            <template slot-scope="scope">
              <el-switch v-if="![27].includes(scope.row.devSecondType)" v-model="scope.row.state" :active-value="1" :inactive-value="0" active-text="开" inactive-text="关" class="switchStyle" active-color="#00A854" inactive-color="#F04134" @change="changeSwitch($event,scope.row)"></el-switch>
              <div v-else>此设备没有开关操作</div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备详细">
            <template slot-scope="scope">
              <!--直接显示操作-->
              <ExternalDetail :row='scope.row' :equipmentInfo='equipmentInfo' :isRealControl="isRealControl" 
                        v-if="equipmentInfo.coolingAndHeatingLamp.secondType.indexOf(scope.row.devSecondType) > -1 ||
                              equipmentInfo.airConditioner.secondType.indexOf(scope.row.devSecondType) > -1 ||
                              equipmentInfo.chamberlainScene.secondType.indexOf(scope.row.devSecondType) > -1"/>
              <!--按钮点击显示操作-->
              <el-button
                :disabled="controlBox.secondType.indexOf(scope.row.devSecondType) > -1?false:scope.row.state==0?true:false"
                size="small"
                type="warning"
                v-else-if="controlBox.secondType.indexOf(scope.row.devSecondType) > -1"
                @click="equipmentOperation(scope.row)"
                >详细设置</el-button>
              <el-row v-else><el-col align="center">此设备只有开关操作</el-col></el-row>
              <!-- 详细设置 -->
               <el-dialog title="详细设置"  :class="controlBox.secondType.indexOf(scope.row.devSecondType) > -1?'controlObjSttingBox':''" :visible.sync="scope.row.dialog" width="550px" :close-on-click-modal="false" append-to-body>
                <EquipmentList ref="EquipmentList" @closeEquipmentList="getEquipmentOperation($event,scope.row)" v-if="scope.row.dialog" :isDialogCaoZuoSheBei="scope.row.dialog" :isDevSecondType="panelForm.devSecondType" :isDevFirstType="panelForm.devFirstType" :panelFormRow="panelForm.row"></EquipmentList>
              </el-dialog>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="positioning(scope.$index, scope.row)" >定位</el-button >
              <el-button size="small" type="danger" @click="removeDev(scope.$index, scope.row)" >移除</el-button >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { standSceneList, scenelnstructionsRemove} from '../../../../api/scene';
import addDeviceIntoList from '././addSeneComponents/addDeviceIntoList.vue';
import EquipmentList from '././addSeneComponents/equipmentList.vue';
import ExternalDetail from '././addSeneComponents/externalDetail.vue';
import { equipmentInfo } from '@/views/equipmentMatters/contextualModel-cpns/batchAdditionDialog/local-host.js'
import { controllDeviceList,controllDeviceItem,locationControl } from '@/api/scene'

export default {
  components: {
    addDeviceIntoList,
    EquipmentList,
    ExternalDetail,
  },
  props: {
    trottingHorseLamp: {
      type: Object,
      required: true,
    },
    scenarioEditor: {
      type: Object,
      required: true,
    },
    sceneId:{
      type:String||Number
    },
    isRealControl:Boolean,
  },
  data() {
    return {
      equipmentInfo,
      flag: false,
      targetEv: [],
      loading: false,
      dialogCaoZuoSheBei: false,
      batchsSetDialog:false,
      
      panelForm: {
        //操作相关
        devSecondType: '',
        devFirstType: '',
        devBrandId: '',
        row: {},
      },

      airConditioner: {
        //空调
        secondType: [4, 42, 43, 49,137,141],
        mode: {
          temperature: '',
          pattern: '',
          windSpeed: '',
        },
      },
      coolingAndHeatingLamp: {
        //冷暖灯
        secondType: [31, 44, 56, 76, 97,98,99],
        mode: {
          brightness: 50,
          colorTemperature: 50,
        },
      },
      adjustingLamp: {
        //调节灯
        secondType: [36, 59],
        mode: {
          brightness: 1,
        },
      },
      underfloorHeatingLamp: {
        //地暖
        secondType: [57],
        mode: {
          brightness: 0,
        },
      },

      lnfraredAlarm: {
        // 红外
        secondType: [93, 95, 106],
        mode: {
          devId: '',
          switchs: '',
          spacing: '',
          threshold: '',
          sensitivity: '',
          periodVaule: '',
          thresholdVaule: '',
          sensitivityVaule: '',
        },
      },
      //窗帘
      percentageCurtain: {
        secondType: [117, 108],
        mode: {
          percentCurtain: 0,
        },
      },
      //单路隔离控制盒
      controlBox:{
        secondType:[144],
        mode: {
            delayTime:0,
            actionTime:0,
        },
      },
      //语音小管家
      chamberlainScene:{
        secondType:[27],
        mode: {
            tv:"",
            airConditioning:"",
            projector:"",
            infraCed:"",
        },
      },
      standSceneListData: {
        loading: false,
        dialogVisible: false,
        sceneList: [],
        devTable: [],
        hsId: '',
      },
      
      selectedDev: [],
      cardDatas: {
        jsiDelay: 0,
      },
      devDetail: {},
      batchsSetButton:[],
      secondTypeArr:[],

    };
  },
  created(){
    console.log(this.selectedDev);
  },
  methods: {
    positioning(index,row){
      locationControl({devId:row.jourDeviceId}).then((res)=>{
        console.log(res)
        if(res.data.status == 200){this.$message.success("定位成功！")}
      }).catch((err)=>{
        this.isError(err,this)
      })
    },
    changeSwitch(value,row){
      console.log("realcontrol",this.isRealControl);
      if(!this.isRealControl){return}
      if(value){
        controllDeviceItem({devState:"开",devId:row.jourDeviceId,}).then(res=>{
          this.$message.success("控制成功！")
        }).catch(err=>{
          this.isError(err,this)
        })
      }else{
        controllDeviceItem({devState:"关",devId:row.jourDeviceId,}).then(res=>{
          this.$message.success("控制成功！")
        }).catch(err=>{
          this.isError(err,this)
        })
      }
    },
    closePageInputVale() {
      console.log(this.$refs.devList)
      Object.assign(this.$refs.devList.form.params, this.$refs.devList.$options.data().form.params)
    },
    callOnlineExecution(){
      this.$emit('onlineExecution',false)
    },
    batchsSetDetail(evetn,item){
      console.log(this.dialogCaoZuoSheBei)
      console.log(evetn,item)
      let row = {
        devFirstType:item.devFirstType,
        devSecondType:item.devSecondType,
        // dialog:item.dialog,
        row:this.devDetail,
      }
      this.equipmentOperation(row)
    },
    batchsSetClose(){
      this.batchsSetButton = [];
      this.secondTypeArr = [];
    },
    batchsSet(){
      // console.log(this.selectedDev)
      // this.secondTypeArr = Array.from(new Set(this.selectedDev.map(item => {if(item.state){return item}})));
      for(let i in this.selectedDev){
        if(this.selectedDev[i].state || this.controlBox.secondType.indexOf(this.selectedDev[i].devSecondType) >-1){
          this.secondTypeArr.push(this.selectedDev[i])
        }
      }
      console.log(this.secondTypeArr,this.batchsSetButton);
      // return
      let obj = {}
      let flag =true;let flag2 = true;let flag3 = true; let flag4 = true; let flag5 =true;let flag6 =true;let flag7 =true;
      console.log(this.secondTypeArr);
      for(let i in this.secondTypeArr){
        let devSecondType = this.secondTypeArr[i].devSecondType;
        let devFirstType = this.secondTypeArr[i].devFirstType;
        if(this.airConditioner.secondType.indexOf(devSecondType) >-1 && flag){
          // console.log("空调")
          obj ={
            buttonName:"空调批量设置",
            devSecondType,
            devFirstType,
            row:{},
          }
          this.batchsSetButton.push(obj)
          flag = false
        }else if(this.coolingAndHeatingLamp.secondType.indexOf(devSecondType) >-1 && flag2){
          // console.log("冷暖灯")
          obj ={
            buttonName:"冷暖灯批量设置",
            devSecondType,
            devFirstType,
            row:{},
          }
          this.batchsSetButton.push(obj)
          flag2 = false
        }else if(this.adjustingLamp.secondType.indexOf(devSecondType) >-1 && flag3){
          // console.log("调节灯")
          obj ={
            buttonName:"调节灯批量设置",
            devSecondType,
            devFirstType,
            row:{},
          }
          this.batchsSetButton.push(obj)
          flag3 = false
        }else if(this.underfloorHeatingLamp.secondType.indexOf(devSecondType) >-1 && flag4){
          // console.log("地暖")
          obj ={
            buttonName:"地暖批量设置",
            devSecondType,
            devFirstType,
            row:{},
          }
          this.batchsSetButton.push(obj)
          flag4 = false
        }else if(this.lnfraredAlarm.secondType.indexOf(devSecondType) >-1 && flag5){
          // console.log("红外")
          obj ={
            buttonName:"红外批量设置",
            devSecondType,
            devFirstType,
            row:{},
          }
          this.batchsSetButton.push(obj)
          flag5 = false
        }else if(this.percentageCurtain.secondType.indexOf(devSecondType) >-1 && flag6){
          // console.log("窗帘")
          obj ={
            buttonName:"窗帘批量设置",
            devSecondType,
            devFirstType,
            row:{},
          }
          this.batchsSetButton.push(obj)
          flag6 = false
        }else if(this.controlBox.secondType.indexOf(devSecondType) >-1 && flag7){
          // console.log("单路隔离控制盒")
          obj ={
            buttonName:"单路隔离控制盒批量设置",
            devSecondType,
            devFirstType,
            row:{},
          }
          this.batchsSetButton.push(obj)
          flag7 = false
        }
      } 
      console.log(this.batchsSetButton.length)
      if(this.batchsSetButton.length<1){this.$message.warning("没有状态为开，并且可以进行详细设置的设备"); return}
      this.batchsSetDialog = true
      // this.secondTypeArr.devSecondType
      // if(airConditioner.secondType.indexOf())
    },
    deleteCurrentPage(){
        this.trottingHorseLamp.stepList.splice(this.trottingHorseLamp.current-1,1)
    },
    select(obj, $event = this.targetEv) {
      console.log(obj, $event.target);
      let me = this;
      if ($event) {
        this.targetEv = $event; // 这里是获取当前点击的input的dom元素赋值给this.targetEv，方便提交后初始化使用
        console.log(this.targetEv);
      }
      if (obj === true) {
        this.flag = false;
      } else {
        this.flag = true;
      }
      this.targetEv.target.checked = obj;
      this.targetEv.target.parentNode.className = obj
        ? 'el-checkbox__input is-checked'
        : 'el-checkbox__input';
        let arr = []
        if(this.isRealControl){
          me.selectedDev.forEach((item,index) => {
          if (obj && !item.state) { //全开并且有关的
              arr.push(controllDeviceItem({devState:"开",devId:item.jourDeviceId,}))
            }
            if (!obj && item.state) {//全关并且有关开的
              arr.push(controllDeviceItem({devState:"关",devId:item.jourDeviceId,}))
            }
            item.state = obj == true ? 1 : 0;
          })
          controllDeviceList(arr).then((res) => {
            this.$message.success('控制成功')
          }).catch(err => {
              this.isError(err, this)
          })
        }else{
           me.selectedDev.forEach((item,index) => {
             item.state = obj == true ? 1 : 0;
           })
        }
    },
    renderHeader(h, { column }) {
      return h('div', [
        h('span', column.label),
        h('el-checkbox', {
          style: 'margin-left:5px;',
          on: {
            change: ($event, column) => this.select($event, column), // 选中事件 $event, column，这里$event=true,column是input的dom当在select里打印的时候
          },
        }),
      ]);
    },
    getEquipmentOperation(data,row) {
      console.log(data,row);
      console.log("batchsSetDialog",this.batchsSetDialog);
      console.log(this.secondTypeArr);
      console.log(this.selectedDev);
      if(this.batchsSetDialog){
        this.dialogCaoZuoSheBei = false;
        for(let i in this.secondTypeArr){
          console.log(this.secondTypeArr[i].devSecondType,this.panelForm.devSecondType)
          if(this.airConditioner.secondType.indexOf(this.secondTypeArr[i].devSecondType) >-1 && this.airConditioner.secondType.indexOf(this.panelForm.devSecondType) > -1){
              console.log("空调")
              for(let j in this.selectedDev){
                if(this.secondTypeArr[i].jourDeviceId == this.selectedDev[j].jourDeviceId){
                  for(let k in data){
                    this.selectedDev[j][k] = data[k]
                  }
                }
              }
              continue;
            }else if(this.coolingAndHeatingLamp.secondType.indexOf(this.secondTypeArr[i].devSecondType)>-1 && this.coolingAndHeatingLamp.secondType.indexOf(this.panelForm.devSecondType) > -1){
              console.log("冷暖灯",this.secondTypeArr[i].jourDeviceId)
              for(let j in this.selectedDev){
                if(this.secondTypeArr[i].jourDeviceId == this.selectedDev[j].jourDeviceId){
                  for(let k in data){
                    this.selectedDev[j][k] = data[k]
                  }
                }
              }
              continue;
            }else if(this.adjustingLamp.secondType.indexOf(this.secondTypeArr[i].devSecondType) >-1 && this.adjustingLamp.secondType.indexOf(this.panelForm.devSecondType) > -1){
              console.log("调节灯")
              continue;
            }else if(this.underfloorHeatingLamp.secondType.indexOf(this.secondTypeArr[i].devSecondType)>-1 && this.underfloorHeatingLamp.secondType.indexOf(this.panelForm.devSecondType) > -1){
              console.log("地暖")
              continue;
            }else if(this.lnfraredAlarm.secondType.indexOf(this.secondTypeArr[i].devSecondType) >-1 && this.lnfraredAlarm.secondType.indexOf(this.panelForm.devSecondType) > -1){
              console.log("红外")
              continue;
            }else if(this.percentageCurtain.secondType.indexOf(this.secondTypeArr[i].devSecondType) >-1 && this.percentageCurtain.secondType.indexOf(this.panelForm.devSecondType) > -1){
              console.log("窗帘")
              continue;
            }else if(this.controlBox.secondType.indexOf(this.secondTypeArr[i].devSecondType) >-1 && this.controlBox.secondType.indexOf(this.panelForm.devSecondType) > -1){
              console.log("单路控制盒")
              for(let j in this.selectedDev){
                if(this.secondTypeArr[i].jourDeviceId == this.selectedDev[j].jourDeviceId){
                  for(let k in data){
                    this.selectedDev[j][k] = data[k]
                  }
                }
              }
              continue;
            }else{
              console.log("未找到此类型",this.secondTypeArr[i].devSecondType)
            }
        }
        console.log(this.secondTypeArr , data)
        this.batchsSetDialog = false;
        this.selectedDev.push()
        return
      }
      row.dialog = false;
      for (let i of this.selectedDev) {
        if (i.jourDeviceId == this.panelForm.row.jourDeviceId) {
          console.log(i.jourDeviceId,this.panelForm.row.jourDeviceId);
          for (let j in data) {
          console.log(j);
            i[j] = data[j];
          }
        }
      }
      console.log(this.selectedDev)
    },
    removeDev(index, row) {
      console.log(this.$refs.devTable.tableData, row);
      for (let i = 0; i < this.$refs.devTable.tableData.length; i++) {
        if (this.$refs.devTable.tableData[i].jourDeviceId == row.jourDeviceId) {
          console.log(this.$refs.devTable.tableData[i]);
          if (this.$refs.devTable.tableData[i].jsiId) {
            let params = {
              instructionId: this.$refs.devTable.tableData[i].jsiId,
            };
            scenelnstructionsRemove(params)
              .then((res) => {
                this.$refs.devTable.tableData.splice(i, 1);
                console.log(res);
              })
              .catch((err) => {
                this.isError(err, this);
              });
          } else {
            this.$refs.devTable.tableData.splice(i, 1);
          }
        }
      }
    },
    equipmentOperation(row) {
      console.log(row)
      this.panelForm.row = row;
      this.panelForm.devSecondType = row.devSecondType;
      this.panelForm.devFirstType = row.devFirstType;
      console.log(this.batchsSetButton.length)
      if(this.batchsSetButton.length < 1){
        this.$set(row,"dialog",true)
      }else{
        this.dialogCaoZuoSheBei = true
      }

      //  = true;
      console.log(this.panelForm.row);
      if (this.airConditioner.secondType.indexOf(row.devSecondType) > -1) {
        //空调详细参数
        for (let i in row) {
          for (let j in this.airConditioner.mode) {
            if (i == j) {
              this.airConditioner.mode[i] = row[j];
            }
          }
        }
      } else if (
        this.coolingAndHeatingLamp.secondType.indexOf(row.devSecondType) > -1
      ) {
        console.log(row, this.coolingAndHeatingLamp.mode);
        //冷暖灯详细参数
        for (let i in row) {
          for (let j in this.coolingAndHeatingLamp.mode) {
            if (i == j) {
              // this.coolingAndHeatingLamp.mode[i] = Number(row[j]);
              this.$nextTick(()=>{
                this.$set(this.coolingAndHeatingLamp.mode,i,Number(row[j]))
              })
              // console.log(this.coolingAndHeatingLamp.mode);
            }
          }
        }
      } else if (
        this.adjustingLamp.secondType.indexOf(row.devSecondType) > -1
      ) {
        //调节灯详细参数
        for (let i in row) {
          for (let j in this.adjustingLamp.mode) {
            if (i == j) {
              this.adjustingLamp.mode[i] = Number(row[j]);
            }
          }
        }
      } else if (
        this.lnfraredAlarm.secondType.indexOf(row.devSecondType) > -1
      ) {
        //红外
        for (let i in row) {
          for (let j in this.lnfraredAlarm.mode) {
            if (i == j) {
              this.lnfraredAlarm.mode[i] = Number(row[j]);
            }
          }
        }
      }
    },
    getSelectedDev(data) {
      if(this.isRealControl){
        data.forEach((key,index) => {
          key.isHove = false
          this.selectedDev.forEach((key1,index1) => {
            if(key.jourDeviceId == key1.jourDeviceId){
              key.isHove = true
            }
          });
        });
        let arr = [];
        data.forEach(key => {
            if(!key.isHove){
              this.selectedDev.push(key)
              arr.push(controllDeviceItem({devState:"关",devId:key.jourDeviceId,}))
            }
        });
        controllDeviceList(arr).then((res) => {
          this.$message.success('控制成功')
        }).catch(err => {
            this.isError(err, this)
        })
      }else{
        data.forEach((key,index) => {
          key.isHove = false
          this.selectedDev.forEach((key1,index1) => {
            if(key.jourDeviceId == key1.jourDeviceId){
              key.isHove = true
            }
          });
        });
        data.forEach(key => {
          if(!key.isHove){
            this.selectedDev.push(key)
          }
        });
        console.log(this.selectedDev)
      }
    },
    // delayTime() {
    //   this.$emit('func', this.cardDatas);
    // },
    addDeviceIntoScene() {

        console.log(this.standSceneListData.hsId,this.scenarioEditor.rowDatas.jsHouseId);
        this.standSceneListData.hsId = this.scenarioEditor.rowDatas.jsHouseId;

        if(!this.standSceneListData.hsId) {this.$message({showClose: true,message: '请选择场景位置',type: 'warning',});return}
        if(!this.scenarioEditor.sceneId){this.$message({showClose: true,message: '请选择场景类别',type: 'warning',});return}

        this.standSceneListData.devTable = this.$refs.devTable.tableData;

        this.standSceneListData.dialogVisible = true;
        this.standSceneListData.loading = true;

        standSceneList({ devNickname: '', devSn: '', devAuthSecret: '',hsIds:[this.standSceneListData.hsId]})
          .then((res) => {
            this.standSceneListData.sceneList = res.data.result;
            console.log('sceneList', this.standSceneListData.sceneList);
            if (this.standSceneListData.sceneList.length < 1) {this.standSceneListData.loading = false;this.$message({ showClose: true, message: '此场景没有设备', type: 'warning', });return}
            this.standSceneListData.loading = false;
          })
          .catch((err) => {
            this.isError(err, this);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.controlObjSttingBox /deep/ .el-dialog__header{
    background-color:#ECECEC;
}
.controlObjSttingBox /deep/ .el-dialog__body{
    background-color:#ECECEC;
}
.controlObjSttingBox /deep/.el-dialog__title{
    font-size:18px;
    color:#9B9C9D;
}
.controlObjSttingBox /deep/.el-input__inner{
    font-size:20px;
    color:#9B9C9D;
}
.deletePage{
  float:right;
  margin-left:20px;
}
.buttonBox{
  float:right;
  .buttonStyle{
    margin-right:10px;
  }
}
button {
  font-size: 14px !important;
}
.clearfix {
  position: relative;
}
.clearfix:after {
  display: table;
  content: '';
  clear: both;
}
</style>
