<template>
    <el-dialog
    title="设置"
    :visible.sync="dialogVisible"
    width="500px"
    append-to-body
    :before-close="handleClose">
    <div>
        <div v-if="settingArr.length === 1">
            <div v-if="equipmentInfo.coolingAndHeatingLamp.secondType.indexOf(settingArr[0]) > -1">
                <el-form label-width="80px">
                    <el-form-item label="亮度" >
                        <el-slider
                        style="margin-left:30px"
                        v-model="setStatus.brightness"
                        :step="5"
                        :min='1' :max='100'
                        :marks="coolingAndHeatingLampMarks">
                        </el-slider> 
                    </el-form-item>
                    <el-form-item label="色温">
                        <el-slider
                        style="margin-left:30px"
                         :min='1' :max='100'
                         :step="5"
                        v-model="setStatus.colorTemperature"
                        :marks="coolingAndHeatingLampMarks">
                        </el-slider>
                    </el-form-item>
                
                </el-form>
            </div>
        </div>
        <div v-if="settingArr.length > 1">
            <div v-if="settingArr === '冷暖灯'">
                <el-form label-width="80px">
                    <el-form-item label="亮度" >
                        <el-slider
                        style="margin-left:30px"
                        v-model="setStatus.brightness"
                        :step="5"
                        :min='1' :max='100'
                        :marks="coolingAndHeatingLampMarks">
                        </el-slider> 
                    </el-form-item>
                    <el-form-item label="色温">
                        <el-slider
                        style="margin-left:30px"
                         :min='1' :max='100'
                         :step="5"
                        v-model="setStatus.colorTemperature"
                        :marks="coolingAndHeatingLampMarks">
                        </el-slider>
                    </el-form-item>
                
                </el-form>
            </div>
        </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleClose('确定')">确 定</el-button>
    </span>
    </el-dialog>
</template>

<script>
import { equipmentInfo } from './local-host'
export default {
    props:{
        dialogVisible:Boolean,
        settingArr: [Array,String],
        setStatus:Object
    },
    data(){
        return {
            equipmentInfo,
            coolingAndHeatingLampMarks:{
                0: '0',
                20: '20',
                40: '40',
                60: '60',
                80: '80',
            }
        }
    },
    watch:{
        "settingArr":{
            handler(newVal){
                console.log(newVal)
            }
        }
    },
    methods:{
        handleClose(flag){
            this.$emit('handleSettingClose',flag)
        }
    }
}
</script>