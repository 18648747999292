<template>
  <div class="Part">
    <!-- 头部开始 -->
    <div class="input">
      <el-row ref="wngRow">
        <el-button size="mini" type="primary" @click="addSceneBtn" v-if="!singleControl">添加场景</el-button>
        <el-button size="mini" type="primary" @click="addBatchBtn" v-if="!singleControl">批量添加场景</el-button>
        <el-button size="mini" type="primary" @click="sceneClssManage" v-if="!singleControl">场景名称管理</el-button>
        <el-button size="mini" type="primary" @click="importProfilesBut" v-if="!singleControl">导入情景模式</el-button>
        <el-button size="mini" type="primary" @click="roomPatternSortBut" v-if="!singleControl">场景模式排序</el-button>
        <el-dialog title="场景模式排序" :visible.sync="roomPatternSort.roomPatternSortDialog" :close-on-click-modal="false" width="1200px" append-to-body>
          <RoomPatternSort v-if="roomPatternSort.roomPatternSortDialog" ref="roomPatternSort"/>
        </el-dialog>
        <el-dialog title="导入情景" :visible.sync="importProfiles.importProfilesDialog" :close-on-click-modal="false" width="80vw" append-to-body>
          <importScenePattern @shuaxinData="importSucceed"/>
        </el-dialog>
        <el-form inline size="mini" label-width="100px">
          <el-form-item v-if="!singleControl">
            场景位置：<el-input v-model="datas.params.address" @change="situationalAddressSearch" clearable size="mini" style="width: 130px;vertical-align:middle"></el-input>
          </el-form-item>
          <el-form-item>
           场景模式：<el-input v-model="datas.params.sceneName"  @change="situationalPatternSearch" clearable size="mini" style="width: 130px;vertical-align:middle"></el-input>
          </el-form-item>
          <el-form-item>
            状态搜索：<el-select @change="searchStatus" v-model="datas.params.sceneState" placeholder="选择状态" clearable style="vertical-align:middle">
                      <el-option size="mini" v-for="item in datas.statusList" :key="item.id" :label="item.value" :value="item.id"> </el-option>
                    </el-select>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
    <!-- 头部结束 -->
    <!-- 创建场景模式 -->
    <el-dialog title="创建场景模式" @close="closeAddSceneData" :visible.sync="addSceneData.dialogVisible1" :close-on-click-modal="false" width="1200px" append-to-body>
      <el-form :model="forms" :rules="rules" ref="forms" style="padding-bottom: 20px;">
        <el-row>
          <el-col :span="5">
            <el-form-item style="cursor: pointer" label="场景位置：" prop="affiliation">
              <el-input v-model="forms.affiliation" @click.native="selectAddressBelong('选择场景位置')" :disabled="addSceneData.isDisabledInput" readonly placeholder="点击选择场景归属位置" style="width: 60%" size="mini" ></el-input> 
            </el-form-item>
          </el-col>
          <el-col :span="5" align="center">
            <el-form-item label="场景类别：" prop="sceneName" >
              <el-select v-model="forms.sceneName" @change="selectSceneCategory" :disabled="addSceneData.isDisabledInput" placeholder="请选择场景类别" size="mini" style="width: 60%" >
                <el-option v-for="item in addSceneData.sceneCategory" :key="item.jscId" :label="item.jscName" :value="item.jscId" ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5" align="center">
            <el-form-item style="padding-bottom: 20px;">
              是否为离线情景：
              <el-switch v-model="addSceneData.isUseOffline" :active-value="true" :inactive-value="false" active-text="是" inactive-text="否" class="switchStyle" active-color="#00A854" inactive-color="#F04134" @change="changeIsUseOffline"
              ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="5" align="center">
            <el-form-item style="padding-bottom: 20px;">
              是否实时控制：
            <el-switch v-model="isRealControl" active-text="是" inactive-text="否" class="switchStyle" active-color="#00A854" inactive-color="#F04134" @change="realControl"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="4" align="center">
            <el-form-item>
              <el-button @click="sceneAddStep" v-if="trottingHorseLamp.isShow" size="mini" type="primary" icon="el-icon-plus">添加场景节点</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item v-if="trottingHorseLamp.isShow" v-loading="scenarioEditor.loading">
          <!-- 走马灯 -->
          <el-carousel :autoplay="false" indicator-position="none" arrow="never" class="carouseHeight" ref="remarkCarusel" >
            <el-carousel-item v-for="item in trottingHorseLamp.stepList" :key="item.id" class="carouseHeight" >
              <delay-time-card ref="child" :key="item.id" :trottingHorseLamp="trottingHorseLamp" :scenarioEditor="scenarioEditor"></delay-time-card>
            </el-carousel-item>
          </el-carousel>
        </el-form-item>
        <el-form-item v-if="trottingHorseLamp.isShow">
          <div class="block">
            <el-pagination background layout="prev, pager, next" :page-size="1" :total="trottingHorseLamp.stepList.length" @prev-click="prevPage" @next-click="nextPage" @current-change="currentPage" :current-page="trottingHorseLamp.current"></el-pagination>
          </div>
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button @click="sceneSubmit('forms')" v-if="trottingHorseLamp.isShow" type="primary">提 交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 编辑场景模式 -->
    <el-dialog title="编辑场景模式" @close="closeAddSceneData" :visible.sync="addSceneData.dialogVisible" :close-on-click-modal="false" width="1200px" append-to-body>
      <el-form :model="forms" :rules="rules" ref="forms" style="padding-bottom: 20px;">
        <el-row>
          <el-col :span="5" align="center">
            <el-form-item style="cursor: pointer" label="场景位置：" prop="affiliation" >
              <el-input size="mini" v-model="forms.affiliation" style="width: 60%" readonly :disabled="addSceneData.isDisabledInput" @click.native="selectAddressBelong('选择场景位置')" placeholder="点击选择场景归属位置" ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5" align="center">
            <el-form-item label="场景类别：" prop="sceneName" >
              <el-select v-model="forms.sceneName" placeholder="请选择场景类别" size="mini" style="width: 60%" @change="selectSceneCategory" :disabled="addSceneData.isDisabledInput">
                <el-option v-for="item in addSceneData.sceneCategory" :key="item.jscId" :label="item.jscName" :value="item.jscId" ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5" align="center">
            <el-form-item>
              是否为离线情景：
              <el-switch v-model="addSceneData.isUseOffline" :active-value="true" :inactive-value="false" active-text="是" inactive-text="否" class="switchStyle" active-color="#00A854" inactive-color="#F04134" @change="changeIsUseOffline"></el-switch>
            </el-form-item>
          </el-col>

          <el-col :span="5" align="center">
            <el-form-item>
              是否实时控制：
            <el-switch v-model="isRealControl" active-text="是" inactive-text="否" class="switchStyle" active-color="#00A854" inactive-color="#F04134" @change="realControl" ></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="4" align="center">
            <el-form-item>
              <el-button size="mini" type="primary" icon="el-icon-plus" @click="sceneAddStep" v-if="trottingHorseLamp.isShow1" >添加场景节点</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item v-if="trottingHorseLamp.isShow1" v-loading="scenarioEditor.loading">
          <!-- 走马灯 -->
          <el-carousel :autoplay="false" indicator-position="none" arrow="never" class="carouseHeight" ref="remarkCarusel" >
            <el-carousel-item v-for="item in trottingHorseLamp.stepList" :key="item.id" class="carouseHeight">
              <delay-time-card ref="child" :key="item.id" @onlineExecution="sceneLinkageBtn" :isRealControl='isRealControl' :trottingHorseLamp="trottingHorseLamp" :scenarioEditor="scenarioEditor"></delay-time-card>
            </el-carousel-item>
          </el-carousel>
        </el-form-item>
        <el-form-item v-if="trottingHorseLamp.isShow1">
          <div class="block">
            <el-pagination background layout="prev, pager, next" :page-size="1" :total="trottingHorseLamp.stepList.length" @prev-click="prevPage" @next-click="nextPage" @current-change="currentPage" :current-page="trottingHorseLamp.current"></el-pagination>
          </div>
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button type="primary" @click="sceneSubmit('forms')" v-if="trottingHorseLamp.isShow1" >保 存</el-button >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 场景名称管理 -->
    <el-dialog title="场景名称管理" :visible.sync="sceneKindPipe.dialogVisible" :close-on-click-modal="false" append-to-body width="50%">
      <el-row>
        <el-col :span="24">
          <el-button @click="addSceneNameDialog" size="small" type="primary">添加场景模式</el-button>
        </el-col>
        <el-dialog title="场景名称管理" :visible.sync="sceneKindPipe.dialogVisible1" :close-on-click-modal="false" append-to-body width="55%">
          <el-row>
            <el-col :span="14" align="bottom">
              <span>场景模式名称：</span>
              <el-input v-model="sceneKindPipe.sceneName" size="mini" type="text" style="width:200px" placeholder="请输入场景模式名称" clearable></el-input>
            </el-col>
            <el-col :span="4" align="bottom">
              <el-button @click="addSceneName" size="mini" type="primary">添 加</el-button>
            </el-col>
          </el-row>
          <el-row :gutter="10" style='margin-top:15px'>
            <el-col :span="9">
                <span style="font-size:16px;">场景类别 : </span>
                <el-select size="mini" clearable v-model="search.searchIconType" @change='getPicList' >
                  <el-option
                    v-for="item in iconTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-col>
          </el-row>
          <el-row class="addSceneDialog-row">
            <el-col>
                <div v-for="(item, index) in sceneKindPipe.imgList" :key="index" :class="[{seleCurrentImg:item.toggleImgClass}, 'imgBox']" @dblclick="imageMagnification(item.iconUrl)" @click="seleImg(item,item.iconUrl,index)">
                  <el-tooltip :content="item.iconName" placement="top" effect="light">
                    <img style="background-color:#ccc;" :src="item.iconUrl" :title="item.iconName" draggable="false"/>
                  </el-tooltip>
                </div>
              <!-- 遮罩层 -->
              <div class="mask" v-if="sceneKindPipe.currentImg" @click="closeImg"></div>
              <div class="current-img" v-if="sceneKindPipe.currentImg">
                <img :src="sceneKindPipe.imgUrl" alt="">
                <span class="close-img" @click="closeImg">X</span>
              </div>
            </el-col>
          </el-row>
        </el-dialog>
      </el-row>
      <el-table :data="addSceneData.sceneCategory" v-loading="sceneKindPipe.loading" height="60vh" style="margin-bottom:5px">
        <!--数据表-->
        <el-table-column label="场景图片" min-width="50px" align="center">
          <template slot-scope="scope">
              <img style="padding:4px;width:80px;height:80px;background-color:#ccc;" :src="scope.row.jscScenePhoto" alt="" v-if="scope.row.jscScenePhoto !='' &&scope.row.jscScenePhoto" @click='changeIcon(scope.$index,scope.row)'>
              <span  @click='changeIcon(scope.$index,scope.row)' v-else>暂无图片</span>
              <el-dialog
                title="选择图标"
                :visible.sync="changIcondialog"
                width="65%"
                :close-on-click-modal="true"
                append-to-body >
                <div> 
                  <el-row :gutter="10" style='margin-top:15px'>
                    <el-col :span="12">
                        <span style="font-size:16px;">场景类别 : </span>
                        <el-select size="mini" clearable v-model="search.searchIconType" @change='getPicList' >
                          <el-option
                            v-for="item in iconTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                    </el-col>
                  </el-row>
                  <el-row class="addSceneDialog-row">
                    <el-col>
                        <div v-for="(item, index) in sceneKindPipe.imgList" :key="index" :class="[{seleCurrentImg:item.toggleImgClass}, 'imgBox']"  @click="selectIncon(item)">
                          <el-tooltip :content="item.iconName" placement="top" effect="light">
                            <img style="background-color:#ccc;" :src="item.iconUrl" :title="item.iconName" draggable="false"/>
                          </el-tooltip>
                        </div>
                    </el-col>
                  </el-row>
                </div>
              </el-dialog>
          </template>
        </el-table-column>
        <el-table-column label="场景模式" min-width="50px" align="center">
          <template slot-scope="scope">
            <el-col v-if="scope.row.buttonShow == true">
              <el-input ref="jscName" v-model="scope.row.jscName" size="small" style="width:150px" clearable ></el-input></el-col>
            <span v-else style="width:150px">{{ scope.row.jscName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="50px" align="center">
          <template slot-scope="scope">
            <el-button size="small" type="warning" @click="cancelModify(scope.row)" v-if="scope.row.buttonShow == true"> 取 消</el-button>
            <el-button type="success" size="small" @click="saveModify(scope.row)" v-if="scope.row.buttonShow == true">保 存</el-button>
            <el-button type="danger" size="small" @click="deleSceneName(scope.row)" v-if="scope.row.buttonShow == true">删 除</el-button>
            <el-button v-else size="small" type="primary" @click="editSceneType(scope.row)">编 辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-table :data="datas.records" :height="heightTable?heightTable:tableHeight" v-loading="datas.loading" @row-dblclick="dbRowModel" highlight-current-row>
      <el-table-column prop="sceneAddress" label="场景位置" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.hsAddCommunity }} {{ scope.row.hsAddFloor }} {{ scope.row.hsAddDoorplateno }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="jscName" label="场景模式" align="center" ></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" :disabled="linkDisabled" @click="sceneLinkageBtn(scope.$index, scope.row)" >在线执行</el-button>
          <el-button size="mini" type="primary" @click="matchingPanel(scope.$index, scope.row)" >配对面板</el-button>
          <el-button size="mini" type="danger" @click="deleScene(scope.row)">删 除</el-button>
          <el-switch size="mini" v-model="scope.row.jsCurrentState" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用" class="switchStyle" active-color="#00A854" inactive-color="#F04134" @change="changeSwitch($event,scope.row)"></el-switch>
          <el-dialog title="配对面板" :visible.sync="scope.row.matchingPanelDialog" @close="closeMatchingPanelDialog(scope.row)" :close-on-click-modal="false" append-to-body main-width="500px" width="50%">
            <el-button size="mini" type="primary" :disabled="linkDisabled" @click="offlineMatching(scope.$index,scope.row )" >离线配对</el-button>
            <el-button size="mini" type="primary" :disabled="linkDisabled" @click="onlineMatching(scope.$index,scope.row)" >在线配对</el-button>
          </el-dialog>
          <el-dialog title="在线情景面板配对" :visible.sync="scope.row.onlineDialogVisible" :close-on-click-modal="false" append-to-body main-width="1000px" width="50%" destroy-on-close @close="closeOnlineMatching(scope.row)">
            <el-form ref="onlineMatching" :inline="true" :model="sceneLinkage" label-width="100px" class="demo-ruleForm">
              <el-form-item prop="devId" label="选择设备：" :rules="[{ required: true, message: '请选择设备', trigger: ['change'] }, ]">
                <el-select v-model="sceneLinkage.devId" size="mini" style="width:200px; margin-right: 10px" placeholder="请选择设备" @change="changeMechanismDevic($event)">
                  <el-option v-for="(item,index) in sceneLinkage.tableList" :key="index" :label="item.devNickname" :value="item.jourDeviceId" :disabled="item.disabled"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="aisle" label="设备通道：" :rules="[{ required: true, message: '请选择设备通道', trigger: ['change'] }, ]">
                <el-select v-model="sceneLinkage.aisle" size="mini" style="width:200px; margin-right: 10px" placeholder="请选择触发动作" @change="changeMechanismMotion($event)" >
                  <el-option v-for="(item, index) in sceneLinkage.actionList" :key="index" :label="item.name" :value="item.cpKeyValue" :disabled="item.disabled"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" @click="onlinePair('onlineMatching')">配对</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
          <el-dialog title="离线情景面板配对" :visible.sync="scope.row.offlineDialogVisible" :close-on-click-modal="false" append-to-body main-width="500px" width="50%" @close="closeOfflineMatching(scope.row)">
            <el-form ref="offlineMatching" :inline="true"  :model="sceneLinkage" label-width="100px" class="demo-ruleForm">
              <el-form-item prop="devAuthSecret" label="网关：" :rules="[{ required: true, message: '请选择网关', trigger: ['change'] }, ]">
                <el-select placeholder="请选择网关" v-model="sceneLinkage.devAuthSecret" clearable size="mini" style="width:200px; margin-right: 10px">
                  <el-option v-for="item in sceneLinkage.GatewayList" :key="item.devAuthSecret" :label="item.label" :value="item.devAuthSecret"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button @click="sceneCodePair('offlineMatching')" type="primary" size="mini">匹 配</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
        </template>
      </el-table-column>
    </el-table>
    
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="datas.params.cursor"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="datas.params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datas.total"
      >
      </el-pagination>
    </div>
    <!-- 任务归属对话框开始 -->
    <select-address v-if="taskAffiliationDialog" :isDialogShow="taskAffiliationDialog" :isTitle="taskAffiliationDialogTitle" @close="selectRoomDblclick"></select-address>
    <!-- 任务归属对话框结束 -->
    <BatchAdditionDialog  v-if="batchAdditionDialogFlag" ref="batchAdditionDialog" :dialogVisible="batchDialogVisible"  @handleBatchDialogClose="handleBatchDialogClose"/>
  </div>
</template>
<script>
import sceneEquipment from './components/sceneManagement/sceneEquipment.vue';
import delayTimeCard from './components/sceneManagement/delayTimeCard.vue';
// import linkageScene from './components/sceneManagement/linkageScene.vue';
import RoomPatternSort from './components/sceneManagement/roomPatternSort.vue';
import selectAddress from '../../components/selectAddress/selectAddress.vue';
import BatchAdditionDialog from './contextualModel-cpns/batchAdditionDialog'
import importScenePattern from './components/contextual/importScenePattern.vue';
import { devAisle } from '@/components/linkage/assets/js/linkage.js'

import {
  queryHouseWithScenePage,
  saveScene,
  getAllSceneCategory,
  infoScene,
  standSceneList,
  sceneLinkageSave,
  sceneList,
  devicesAll6200Mac,
  sceneLinkageMatchController,//离线配对
  sceneCategoryAdd,
  sceneModeControl,
  sceneCategoryModify,
  standLinkageList,
  linkageOffline,//在线配对
  sceneControlScene,//禁用启用
  sceneSelectSceneCategory,//查询场景名称是否存在
  sceneCategoryDelete,//删除情景名称
  sceneDeleteScene,//删除场景
  sceneCategoryIcon,//场景图片
} from '../../api/scene';
export default {
  name: 'contextualModel',
  props:["heightTable","isSingleControl","houseNumber"],
  components: {
    sceneEquipment,
    delayTimeCard,
    selectAddress,
    // linkageScene,
    BatchAdditionDialog,
    importScenePattern,
    RoomPatternSort,
  },
  data() {
    return {
      isRealControl:false,
      batchAdditionDialogFlag:true,
      batchDialogVisible:false,
      singleControl:this.isSingleControl||false,
      activeName: 'first',
      tableHeight: 0,
      linkDisabled: false,
      taskAffiliationDialog: false,
      houseIdArr:[],
      //场景名称管理
      sceneKindPipe: {
        sceneName: '',
        imgUrl:'',
        currentImg:false,
        loading: false,
        dialogVisible: false,
        dialogVisible1: false,
        imgList: []
      },
      //面板配对
      panelMatchData: {
        onlineDialogVisible:false,
        offlineDialogVisible: false,
        matchingPanelDialog: false,
      },
      forms: {
        switch:'',
        sceneName: '',
        jsHouseId: '',
        affiliation: '',
      },
      search:{ //图片检索数据
        searchIconState:'',
        searchIconType:'',
        iconUseCategory:''
      },
      iconTypes:[{label:'1:1线图',value:1},{label:'1:1彩图',value:2},{label:'2:4彩图',value:3}],
      sceneSubmitData: {
        jsSceneCategoryId: '',
        sceneName: '',
        jsHouseId: '',
        instructions: [],
      },
      rules: {
        sceneName: [
          {
            required: true,
            message: '场景类别不能为空',
            trigger: 'blur',
          },
        ],
      },
      // 走马灯
      trottingHorseLamp: {
        isShow: false,
        isShow1: false,
        jsId:'',
        sum: 1,
        stepList:[{id:0}],
        current: 1,
      },
      //场景联动
      sceneLinkage: {
        tableListValue: '',
        loading: false,
        aisle:'',
        sn:'',
        tableList: [], //场景联动设备
        actionList:[],
        sceneList: [], //场景联动模式
        GatewayList: [], //场景联动网关
        panelKeyList: [],
        jsId: '',
        devId:'',
        jourDeviceId: '',
        devAuthSecret: '',
        isShow: false,
        isShow1: false,
      },
      iconIndex:'',
      changIcondialog:false,
      //编辑场景模式
      scenarioEditor: {
        loading: false,
        dialogVisible: false,
        rowDatas: {
          jsHouseId: null,
        }, //选中行的信息
        sceneId: '', //当前房间选中的场景
        scenes: [], //当前房间下的所有场景
        devices: [], //当前房间选中的场景下的设备
      },

      //添加场景模式
      addSceneData: {
        jscId: '',
        sceneCategory: [],
        isUseOffline:false,
        isDisabledInput: false,
        dialogVisible: false,
        dialogVisible1: false,
      },
      datas: {
        loading: false,
        records: [],
        statusList:[{id:1,value:"启用"},{id:0,value:"禁用"}],//状态
        params: {
          address: '',
          sceneName: '',
          cursor: 1, // 当前页数
          limit: 10, // 当前条数
          sceneState:'',//状态搜索
        },
        total: 0,
      },
      sceneListData: [],
      //导入情景
      importProfiles:{
        importProfilesDialog:false,
        sceneData:'',
      },
      //房间模式排序
      roomPatternSort:{
        roomPatternSortDialog:false,
      },
    };
  },
  watch: {
    'trottingHorseLamp.stepList.length': {
      handler: function(val, oldVal) {
        this.$nextTick(function() {
          if (this.trottingHorseLamp.isShow || this.trottingHorseLamp.isShow1) {
            this.setActiveItem(val);
          }
        });
      },
      // deep: true
    },
  },
  created() {
    console.log(this.heightTable,this.singleControl,this.houseNumber)
    this.getAllSceneCategory();
    if(this.singleControl){
      this.datas.params.address = this.houseNumber;
      this.pageHouseDeposit();
      return
    }
    this.pageHouseDeposit();
  },
  updated() {
    this.tableHeight =
      window.innerHeight - this.$refs.wngRow.$el.offsetHeight - 150;
  },
  methods: {
    closeMatchingPanelDialog(row){
      console.log(row)
      this.$set(row,'matchingPanelDialog',false);
      [].push()
    },
    roomPatternSortBut(){
      this.roomPatternSort.roomPatternSortDialog=true;
    },
    changeSwitch(value,row){
      this.operation(value,row)
    },
    operation(state,row){//注销and启动
      sceneControlScene({
        sceneId:row.jsId,
        state:state
      }).then((res)=>{
        console.log(res)
        this.pageHouseDeposit();
      }).catch((err)=>{
        this.isError(err,this)
      })
    },
    searchStatus(){
      this.pageHouseDeposit();

    },
    realControl(value){

    },
    importSucceed(){
      this.pageHouseDeposit();
    },
    importProfilesBut(){
      this.importProfiles.importProfilesDialog = true
    },
    changeIsUseOffline(){
      if(this.$refs.child.length>1){
        this.addSceneData.isUseOffline = false;
        this.$message.warning('场景节点只有一个时,才能开启支持离线');
      }
    },
    closeOfflineMatching(row){
      console.log(row)
      row.offlineDialogVisible = false
      this.sceneLinkage.devAuthSecret = '';
    },
    closeOnlineMatching(row){
      row.onlineDialogVisible = false
      this.sceneLinkage.devId = '';
      this.sceneLinkage.aisle = '';
    },
    changeMechanismDevic(devId){
      this.sceneLinkage.aisle = "";
      this.sceneLinkage.tableList.forEach((item,index)=>{
        if(item.jourDeviceId ===devId){
          this.sceneLinkage.sn = item.devSn
          this.sceneLinkage.actionList = devAisle(item.devSecondType)
        }
      })
    },
    changeMechanismMotion(aisle){
      console.log(aisle);
    },
    sceneCodePair(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            sceneId: this.scenarioEditor.rowDatas.jsId,
            mac: this.sceneLinkage.devAuthSecret,
          };
          sceneLinkageMatchController(params).then((res) => {
            if (res.data.status == 200) {
              this.$message({
                showClose: true,
                message: '匹配成功',
                type: 'success',
              });
            }
          })
          .catch((err) => {
            this.isError(err, this);
          });
        } else {
          return false;
        }
      });

    },
    onlinePair(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          linkageOffline({channel:this.sceneLinkage.aisle,sn:this.sceneLinkage.sn,sceneId:this.scenarioEditor.rowDatas.jsId}).then(res =>{
            this.$message.success('配对成功')
          }).catch(err =>{
            this.isError(err)
          })
        } else {
          return false;
        }
      });
    },
    getPicList(){
      sceneCategoryIcon({
          iconType:this.search.searchIconType,
          iconState:"正常",
          iconUseCategory:2,
          iconId:'',
          iconName:'',
          iconUrl:'',
          iconExplain:'',
          iconTime:''
      }).then(res=>{
        this.sceneKindPipe.imgList = res.data.result 
      }).catch(err=>{
        this.isError(err,this)
      })
    },
    addBatchBtn(){
      this.batchAdditionDialogFlag = true
      this.$nextTick(() => {
        this.batchDialogVisible = true
        this.$refs.batchAdditionDialog.getSceneInfo(true)
      })
    },
    handleBatchDialogClose(){
      this.batchAdditionDialogFlag = false
      this.batchDialogVisible = false
    },
    addSceneNameDialog(){
      this.sceneKindPipe.dialogVisible1 = true;
      sceneCategoryIcon(
        {
          iconId:'',
          iconState: '正常',
          iconUseCategory: 2,
          iconType:'',
          iconName:'',
          iconUrl:'',
          iconExplain:'',
          iconTime:''}).then(res=>{
        this.sceneKindPipe.imgList = res.data.result
        for(let i in this.sceneKindPipe.imgList){
          this.$set(this.sceneKindPipe.imgList[i],"toggleImgClass",false)
        }
      }).catch(err=>{
        this.isError(err,this)
      })
    },
    changeIcon(index,row){
      if(row.buttonShow == false){
        return
      }
      sceneCategoryIcon(
        {
          iconId:'',
          iconState: '正常',
          iconUseCategory: 2,
          iconType:'',
          iconName:'',
          iconUrl:'',
          iconExplain:'',
          iconTime:''}).then(res=>{
        this.sceneKindPipe.imgList = res.data.result
      }).catch(err=>{
        this.isError(err,this)
      })
      this.changIcondialog = true
      this.iconIndex = index
      console.log(this.addSceneData.sceneCategory[this.iconIndex])
    },
    
    selectIncon(item){
       this.addSceneData.sceneCategory[this.iconIndex].jscScenePhoto = item.iconUrl
       this.changIcondialog = false
    },
    selectSceneDev(value) {
      console.log(value);
      this.sceneLinkage.isShow1 = true;
      console.log(this.sceneLinkage.tableList);
      let array = [];
      switch (value) {
        case 17:
          array = [
            { name: '1号 按键', spdId: '', cpKeyValue: 0 },
            { name: '2号 按键', spdId: '', cpKeyValue: 1 },
            { name: '3号 按键', spdId: '', cpKeyValue: 2 },
            { name: '4号 按键', spdId: '', cpKeyValue: 3 },
          ];
          break;
        case 38:
        case 115:
        case 18:
          array = [
            { name: '插卡取电', spdId: '', cpKeyValue: 0 },
            { name: '拔卡断电', spdId: '', cpKeyValue: 1 },
          ];
          break;
        case 22:
          array = [
            { name: '1号 按键', spdId: '', cpKeyValue: 0 },
            { name: '2号 按键', spdId: '', cpKeyValue: 1 },
          ];
          break;
        case 28:
          array = [
            { name: '我回家了', spdId: '', cpKeyValue: 0 },
            { name: '我出门了', spdId: '', cpKeyValue: 1 },
            { name: '就餐模式', spdId: '', cpKeyValue: 2 },
            { name: '影音模式', spdId: '', cpKeyValue: 3 },
            { name: '会客模式', spdId: '', cpKeyValue: 4 },
            { name: '我要睡了', spdId: '', cpKeyValue: 5 },
            { name: '起床模式', spdId: '', cpKeyValue: 6 },
            { name: '我要看书', spdId: '', cpKeyValue: 7 },
            { name: '吃 饭 了', spdId: '', cpKeyValue: 8 },
            { name: '休闲模式', spdId: '', cpKeyValue: 9 },
            { name: ' 晚 安 ', spdId: '', cpKeyValue: 10 },
          ];
          break;
        case 58:
        case 60:
          array = [{ name: '开锁联动', spdId: '', cpKeyValue: 0 }];
          break;
        case 129:
          array = [
            { name: '我回家了', spdId: '', cpKeyValue: 1 },
            { name: '我出门了', spdId: '', cpKeyValue: 0 },
            { name: '睡眠模式', spdId: '', cpKeyValue: 2 },
            { name: '休闲模式', spdId: '', cpKeyValue: 3 },
            { name: '会客模式', spdId: '', cpKeyValue: 4 },
            { name: '影院模式', spdId: '', cpKeyValue: 5 },
            { name: '就餐模式', spdId: '', cpKeyValue: 6 },
          ];
        default:
          break;
      }
      this.sceneLinkage.panelKeyList = array;
      if (this.sceneLinkage.panelKeyList) {
        this.$nextTick(function() {
          this.getSceneLinkageInfo();
        });
      }
      console.log(this.panelKeyList);
    },
    editSceneType(row) {
      //编辑按钮
      row.buttonShow = true;
      this.$nextTick(() => {
        this.$refs.jscName.focus();
      });
    },
    //删除场景模式
    deleScene(row){
      this.$confirm('是否删除此场景模式', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      sceneDeleteScene({sceneId:row.jsId}).then(res=>{
        this.$message.success('删除成功')
        this.pageHouseDeposit();
      }).catch(err=>{
        this.isError(err,this)
      })
      }).catch((err) => {
        console.log(err);
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    //删除场景名称
    deleSceneName(row){
      sceneSelectSceneCategory({sceneCategoryId:row.jscId}).then(res =>{
        if(row.jscName === res.data.result){
          this.$message.warning('该名称有场景模式，请解除后再删除。')
          return
        }

        this.$confirm('是否删除此场景名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          sceneCategoryDelete({jsSceneCategoryId:row.jscId,}).then(res=>{
            this.$message.success('删除成功！')
            this.getAllSceneCategory()
          }).catch(err=>{
            this.isError(err,this)
          })

        }).catch((err) => {
          console.log(err);
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      }).catch(err=>{
        this.isError(err,this)
      })

    },
    cancelModify(row) {
      console.log(row)
      row.jscName = row.oldJscName;
      row.buttonShow = false;
    },
    saveModify(row) {
      console.log(row);
      if (row.jscName) {
        row.buttonShow = false;
        if (true) {
          row.oldJscName = row.jscName;
          let params = {
            id: row.jscId,
            name: row.jscName,
            photo: row.jscScenePhoto,
          };
          sceneCategoryModify(params)
            .then((res) => {
              if (res.data.result) {
                this.$message({
                  showClose: true,
                  message: '保存成功',
                  type: 'success',
                });
              }
              console.log(res);
            })
            .catch((err) => {
              this.isError(err, this);
            });
        } else {
          this.$message({
            showClose: true,
            message: '保存成功',
            type: 'success',
          });
        }
      } else {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '类型模式不能为空',
        });
      }
    },

    matchingPanel(index,row){
      console.log(row);
      this.$set(row,'matchingPanelDialog',true)
      this.scenarioEditor.rowDatas = row
    },
    offlineMatching(index,row) {
      console.log(this.scenarioEditor.rowDatas.jsIsOffline);
      if(this.scenarioEditor.rowDatas.jsIsOffline === 0){
        this.$confirm('此场景未启用支持离线执行，使用过程可能无法执行。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getGatewayList();
          this.$set(row,'offlineDialogVisible',true)
        }).catch(() => {
        });
        return
      }
      this.getGatewayList();
      this.$set(row,'offlineDialogVisible',true)
    },
    onlineMatching(index,row){
      this.$set(row,'onlineDialogVisible',true)
      this.getStandLinkageList()
    },
    seneLinkSubmit() {
      let panelKeyList = this.$refs.linkageScene.panelKeyList;
      console.log(panelKeyList);
      if (panelKeyList.length > 0) {
        let data = [];
        for (let i in panelKeyList) {
          if (panelKeyList[i].spdId) {
            let obj = {};
            (obj.jslId = ''), (obj.jslType = 0);
            obj.jslAisle = panelKeyList[i].cpKeyValue;
            obj.jslHouseId = this.scenarioEditor.rowDatas.jsHouseId;
            obj.jslTriggerDevice = this.sceneLinkage.jourDeviceId;
            obj.jslSceneCategory = panelKeyList[i].spdId;
            data.push(obj);
          }
        }
        console.log(data);
        sceneLinkageSave(data)
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              this.$message({
                showClose: true,
                message: '保存成功',
                type: 'success',
              });
            }
          })
          .catch((err) => {
            this.isError(err, this);
          });
      } else {
        this.$message({
          showClose: true,
          message: '请选择一个设备',
          type: 'warning',
        });
      }
    },
    tabClick(tab) {
      console.log(tab.name);
      if (tab.name == 'second') {
        this.sceneLinkage.isShow = true;
      }
    },
    //场景位置搜索
    situationalAddressSearch(value) {
      this.datas.params.address = value;
      this.pageHouseDeposit();
    },
    //场景模式搜索
    situationalPatternSearch(value) {
      this.datas.params.sceneName = value;
      this.pageHouseDeposit();
    },
    selectSceneCategory(value) {
      console.log(value);
      this.sceneSubmitData.jsSceneCategoryId = value;
      this.addSceneData.jscId = value;
      this.scenarioEditor.sceneId = value;
      if (this.scenarioEditor.rowDatas.jsHouseId) {
        console.log(this.scenarioEditor.rowDatas.jsHouseId);
        if (this.trottingHorseLamp.stepList.length >= 1) {
          this.trottingHorseLamp.stepList = [{id:0}];
          this.trottingHorseLamp.current = 1;
          // this.$refs.child[0].$refs.devTable.tableData.length = 0;
          // this.$refs.child[0].$refs.devList.standSceneListData.sceneList.length = 0;
        }
        this.initData()
      }
      this.addSceneData.sceneCategory.find((item) => {
        if (item.jscId == value) {
          this.sceneSubmitData.sceneName = item.jscName;
        }
      });
      console.log(this.sceneSubmitData);
    },
    getInfoScene() {
      return new Promise(resolve => {
      infoScene({
        categoryId: this.addSceneData.jscId,
        hsId: this.scenarioEditor.rowDatas.jsHouseId,
      }).then((res) => {
          console.log(res.data.result);
          resolve(res.data.result);
        })
        .catch((err) => {
          this.isError(err, this);
        });
      })
    },
    selectAddressBelong(str) {
      //选择地址
      if (!this.addSceneData.isDisabledInput) {
        console.log('hhh');
        this.taskAffiliationDialogTitle = str;
        this.taskAffiliationDialog = true;
      }
    },
    selectRoomDblclick(row) {
      console.log(row);
      if (row) {
        if (
          this.scenarioEditor.rowDatas.jsHouseId == null ||
          this.scenarioEditor.rowDatas.jsHouseId != row.hsId
        ) {
          this.taskAffiliationDialog = false;
          this.forms.affiliation = row.address;
          console.log(row.hsId);
          this.scenarioEditor.rowDatas.jsHouseId = row.hsId;
          console.log(this.addSceneData.jscId);
            if (this.addSceneData.jscId) {
              if (this.trottingHorseLamp.stepList.length >= 1) {
                this.trottingHorseLamp.stepList = [{id:0}];
                this.trottingHorseLamp.current = 1;
                this.$refs.child[0].$refs.devTable.tableData.length = 0;
                // this.$refs.child[0].$refs.devList.standSceneListData.sceneList.length = 0;
              }
              this.scenarioEditor.loading = true;
              this.initData()
            }

          console.log('不相同');
        } else {
          this.taskAffiliationDialog = false;
          console.log('相同');
        }
      } else {
        this.taskAffiliationDialog = false;
      }
    },
    //添加提交
    sceneSubmit(formName) {
      console.log(this.sceneSubmitData,"333333333333333");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var arr = []; //每个任务
          for (const i in this.$refs.child) {
            let cardData = this.$refs.child[i].$refs.devTable.tableData;
            let jsiDelay = i==0? 0: this.$refs.child[i].cardDatas.jsiDelay;
            for (let item of cardData) {
              let obj = {}; //每个任务的延时时间
              obj.devAuthSecret = item.devAuthSecret ? item.devAuthSecret : '';
              obj.devFirstType = item.devFirstType;
              obj.devNickname = item.devNickname;
              obj.devSecondType = item.devSecondType;
              obj.devSn = item.devSn;
              obj.jsiDelay = jsiDelay;
              obj.jsiDeviceId = item.jourDeviceId;
              obj.jsiSort = +i + 1;
              let jsiSetStatus = {};
              jsiSetStatus.state = item.state == 1 ? true : false;
              switch (obj.devSecondType) {
                case 31:
                case 44:
                case 56:
                case 76:
                case 97:
                case 98:
                case 99:
                  if (jsiSetStatus.state){
                      jsiSetStatus.brightness = item.brightness? item.brightness: 1;
                      jsiSetStatus.colorTemperature = item.colorTemperature? item.colorTemperature: 1;
                    }
                  break;
                case 43:
                case 141:
                case 137:
                  if (jsiSetStatus.state){
                      jsiSetStatus.temperature = item.temperature? item.temperature: 20;
                      jsiSetStatus.pattern = item.pattern? item.pattern: '制冷';
                      jsiSetStatus.windSpeed = item.windSpeed? item.windSpeed: '自动';
                    }
                  break;
                case 144:
                      jsiSetStatus.delayTime = item.delayTime? item.delayTime: 0;
                      jsiSetStatus.actionTime = item.actionTime? item.actionTime: 0;
                  break;
                case 27:
                      jsiSetStatus.tv = typeof item.tv !='undefined'? item.tv: '';
                      jsiSetStatus.airConditioning = typeof item.airConditioning !='undefined'? item.airConditioning: '';
                      jsiSetStatus.projector = typeof item.projector !='undefined'? item.projector: '';
                      jsiSetStatus.infraCed = typeof item.infraCed !='undefined'? item.infraCed: '';
                  break;
                default:
                  break;
              }
              obj.jsiSetStatus = JSON.stringify(jsiSetStatus);
              arr.push(obj);
            }
            this.sceneSubmitData.instructions = arr;
            this.sceneSubmitData.isUseOffline = this.addSceneData.isUseOffline
          }
          console.log(this.sceneSubmitData);
          console.log(this.sceneSubmitData.instructions);
          // if (this.sceneSubmitData.instructions.length < 1) {this.$message({showClose: true,message: '请添加设备',type: 'warning',}); return}
            this.sceneSubmitData.jsHouseId = this.scenarioEditor.rowDatas.jsHouseId;
            let a = this.$message({
              showClose: true,
              message: '提交中...',
              type: 'warning',
              duration: 0,
            });
            //提交接口
            saveScene(this.sceneSubmitData)
              .then((res) => {
                console.log(res);
                if (res.status == 200) {
                  a.message = '提交成功';
                  a.type = 'success';
                  let time = setInterval(() => {
                    a.close();
                    clearTimeout(time);
                  }, 3000);
                  // this.addSceneData.dialogVisible = false;
                  // this.closeAddSceneData();
                  // this.pageHouseDeposit();
                }
              })
              .catch((err) => {
                a.close();
                this.datas.loading = false;
                this.isError(err, this);
              });
          // } else {
          //   if (this.sceneSubmitData.instructions.length > 0) {
          //     for (let i = 0; i < this.$refs.child.length; i++) {
          //       this.forms.instructions.splice(i, 1);
          //     }
          //   }
          //   this.$message({
          //     showClose: true,
          //     message: '请添加设备',
          //     type: 'warning',
          //   });
          // }
        } else {
          return false;
        }
      });
    },
    currentPage(index) {
      this.setActiveItem(index);
    },
    nextPage(index) {
      this.setActiveItem(index);
    },
    prevPage(index) {
      this.setActiveItem(index);
    },
    setActiveItem(index) {
      this.$refs.remarkCarusel.setActiveItem(index - 1);
      this.trottingHorseLamp.current = index;
    },
    sceneAddStep() {
      console.log(this.$refs.child);
      if(this.addSceneData.isUseOffline && this.trottingHorseLamp.stepList.length >=1){
        this.$message({showClose: true,message: '支持离线,只能使用一个情景节点',type: 'warning',});
        return
      }
      let selectedDevLeng = this.$refs.child[this.trottingHorseLamp.stepList.length - 1].selectedDev;
      console.log(this.$refs.child[this.trottingHorseLamp.stepList.length - 1].selectedDev);
      if(selectedDevLeng.length < 1){
        this.$message({showClose: true,message: '请先添加设备',type: 'warning',});
        this.setActiveItem(this.trottingHorseLamp.stepList.length);
        return
      }
        let obj ={}
        obj.id = this.trottingHorseLamp.stepList[this.trottingHorseLamp.stepList.length -1].id + 1;
        this.trottingHorseLamp.stepList.push(obj);
        this.$message({
          showClose: true,
          message: '添加场景节点成功！',
          type: 'success',
        });
    },
    //清空选择的场景模式
    clearSelectedScenarioMode() {
      this.scenarioEditor.devices = [];
    },
    closeAddSceneData() {
      this.activeName = 'first';
      this.addSceneData.isUseOffline=false,
      this.addSceneData.isDisabledInput = false;
      // this.addSceneData.dialogVisible = false;
      this.addSceneData.dialogVisible1 = false;
      this.addSceneData.jscId = '';
      this.sceneSubmitData = {
        jsSceneCategoryId: '',
        sceneName: '',
        jsHouseId: '',
        instructions: [],
      };
      this.scenarioEditor = {
        loading: false,
        dialogVisible: false,
        rowDatas: {
          jsHouseId: null,
        }, //选中行的信息
        sceneId: '', //当前房间选中的场景
        scenes: [], //当前房间下的所有场景
        devices: [], //当前房间选中的场景下的设备
      };
      this.forms = {
        sceneName: '',
        jsHouseId: '',
        affiliation: '',
      };
      this.trottingHorseLamp = {
        stepList:[{id:0}],
        isShow: false,
        isShow1: false,
        jsId:'',
        sum: 1,
        current: 1,
      };
      this.sceneLinkage = {
        dialogVisible: false,
        loading: false,
        tableList: [], //场景联动设备
        sceneList: [], //场景模式联动
        GatewayList: [], //场景联动网关
        panelKeyList: [],
        devAuthSecret: '',
        jsId: '',
        jourDeviceId: '',
        isShow: false,
        isShow1: false,
      };
      if (this.trottingHorseLamp.isShow) {
        this.$refs.child[0].$refs.devTable.tableData.length = 0;
        // this.$refs.child[0].$refs.devList.standSceneListData.sceneList.length = 0;
      }
    },
    // 选择场景模式
    //查询单个教室/公区/办公室绑定的设备
    queryFacility(scenarios, scenariosPanel) {
      this.$axios({
        url: `/common2/house4stores/${this.scenarioEditor.rowDatas.jsHouseId}/devices`,
        params: {
          scenarios: scenarios,
          scenariosPanel: scenariosPanel,
        },
      })
        .then((res) => {
          this.scenarioEditor.devices = res.data.result.records;
          console.log('devices', this.scenarioEditor.devices);
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    //联动按钮
    sceneLinkageBtn(index, row) {
      this.linkDisabled = true;
      let params = {
        jsId: this.trottingHorseLamp.jsId || row.jsId,
      };
      let a = this.$message({
        message: '正在执行' + this.forms.sceneName || row.jscName + '中...',
        duration: 0,
        type: 'warning',
      });
      sceneModeControl(params)
        .then((res) => {
          let time = setInterval(() => {
            a.close();
            clearInterval(time);
          }, 3000);
          a.message = '联动成功';
          a.type = 'success';
          this.linkDisabled = false;
          console.log(res);
        })
        .catch((err) => {
          a.close();
          this.linkDisabled = false;
          this.isError(err, this);
        });
    },
    // 添加场景按钮 - 双击行
    dbRowModel(row, column, event) {
      console.log(row, column, event);
      this.addSceneData.dialogVisible = true;
      this.addSceneData.isDisabledInput = true;
      this.trottingHorseLamp.isShow1 = true;
      this.trottingHorseLamp.jsId = row.jsId
      this.scenarioEditor.sceneId = row.jsSceneCategoryId;
      this.scenarioEditor.rowDatas.jsHouseId = row.jsHouseId;
      console.log(this.addSceneData.sceneCategory);
      this.forms.affiliation = `${row.hsAddCommunity} ${row.hsAddFloor} ${row.hsAddDoorplateno}`;
      this.forms.sceneName = row.jscName;
      console.log(this.forms.sceneName,this.forms.affiliation)
      for(let i in this.addSceneData.sceneCategory){
        let sceneCategoryItem = this.addSceneData.sceneCategory[i];
        this.sceneSubmitData.jsSceneCategoryId = sceneCategoryItem.jscId;
        if (sceneCategoryItem.jscId == row.jsSceneCategoryId) {
          this.addSceneData.jscId = sceneCategoryItem.jscId;
          if (this.scenarioEditor.rowDatas.jsHouseId) {
            this.initData()
          }
        }
      }
      this.sceneSubmitData.jsSceneCategoryId = row.jsSceneCategoryId;
    },
    async initData(){
      this.scenarioEditor.loading = true;
      let getInfoScene =  await this.getInfoScene()
      console.log(getInfoScene)
      this.addSceneData.isUseOffline = getInfoScene && getInfoScene.jsIsOffline?true:false
      if (getInfoScene && getInfoScene.sceneInstructions.length > 0){
        this.houseIdArr = await Array.from(new Set(getInfoScene.sceneInstructions.map(item=>item.houseId)))
        this.sceneListData = await this.getStandSceneList()
        console.log(this.sceneListData)
        let sceneInstructions = getInfoScene.sceneInstructions;
        let sorts = sceneInstructions.map(function sort(instruction) {return instruction.jsiSort;});
        let set = Array.from(new Set(sorts));
        // this.trottingHorseLamp.sum = set.length;
        let arr = [];
        for (let i in set) {
          if(i!=0){
            let obj ={}
            obj.id = this.trottingHorseLamp.stepList[this.trottingHorseLamp.stepList.length -1].id + 1;
            this.trottingHorseLamp.stepList.push(obj);
          }
          for (let j in sceneInstructions) {
            if (set[i] == sceneInstructions[j].jsiSort) {
              arr.push(sceneInstructions[j].jsiDelay);
              // console.log(sceneInstructions[j]);
              break;
            }
          }
        }
          let child = this.$refs.child;
          let allDevId = [];
          this.$nextTick(()=>{
            for (let i in child) {
              child[i].cardDatas.jsiDelay = arr[i];
            }
          })
          for (let i in this.sceneListData) {
            allDevId.push(this.sceneListData[i].jourDeviceId);
          }
          console.log(set,sceneInstructions,this.sceneListData)
          for (let i in set) {
            console.log(i)
            for (let j in sceneInstructions) {
              for (let k in allDevId) {
                if (sceneInstructions[j].jsiDeviceId == allDevId[k] && set[i] == sceneInstructions[j].jsiSort) {
                  let jsiSetStatus = JSON.parse(sceneInstructions[j].jsiSetStatus);
                  let obj = {};
                  obj.devAuthSecret = this.sceneListData[k].devAuthSecret;
                  obj.devFirstType = this.sceneListData[k].devFirstType;
                  obj.devNickname = this.sceneListData[k].devNickname;
                  obj.devSecondType = this.sceneListData[k].devSecondType;
                  obj.devSn = this.sceneListData[k].devSn;
                  obj.jourDeviceId = this.sceneListData[k].jourDeviceId;
                  obj.jsiId = sceneInstructions[j].jsiId ? sceneInstructions[j].jsiId: 'undefinde';
                  console.log(jsiSetStatus);
                  for (let a in jsiSetStatus) {
                    console.log(jsiSetStatus[a], a);
                    if (a == 'state') {
                      jsiSetStatus[a] = jsiSetStatus[a] == true ? 1 : 0;
                    }
                  }
                  obj = Object.assign({}, obj, jsiSetStatus);
                  this.$nextTick(()=>{
                    child[i].$refs.devTable.tableData.push(obj);
                  })
                  break;
                }
              }
            }
          }
        this.scenarioEditor.loading = false;
      } else {
        this.scenarioEditor.loading = false;
        this.trottingHorseLamp.stepList = [{id:0}];
        let ary = this.$refs.child[0].$refs.devTable.tableData;
        ary.splice(0, ary.length);
      }
    },
    // 添加场景按钮
    addSceneBtn() {
      this.trottingHorseLamp.isShow = true;
      this.addSceneData.dialogVisible1 = true;
    },
    sceneClssManage() {
      this.sceneKindPipe.dialogVisible = true;
    },
    handleSizeChange(val) {
      this.datas.params.limit = val;
      this.pageHouseDeposit();
    },
    handleCurrentChange(val) {
      this.datas.params.cursor = val;
      this.pageHouseDeposit();
    },
    pageHouseDeposit() {
      this.datas.loading = true;
      console.log(this.datas.params);
      let that = this;
      this.datas.params.isByUser = false;
      queryHouseWithScenePage(that.datas.params)
        .then((res) => {
          this.datas.records = res.data.result.records;
          this.datas.total = res.data.result.total;
          this.datas.loading = false;
          console.log('records:', this.datas.records);
        })
        .catch((err) => {
          this.datas.loading = false;
          this.isError(err, this);
        });
    },
    getAllSceneCategory() {
      getAllSceneCategory()
        .then((res) => {
          for (let i of res.data.result) {
            i.buttonShow = false;
            i.oldJscName = i.jscName;
          }
          this.addSceneData.sceneCategory = res.data.result;
          console.log('SceneCategory:', res.data.result);
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    getStandSceneList() {
        return new Promise((resolve)=>{
            standSceneList({
            devNickname: '',
            devSn: '',
            devAuthSecret: '',
            hsIds:this.houseIdArr
          }).then((res) => {
            resolve(res.data.result)
          })
          .catch((err) => {
            this.isError(err, this);
          });
        })
    },
    getSceneList() {
      let params = {
        hsId: this.scenarioEditor.rowDatas.jsHouseId,
        isByUser: false,
      };
      sceneList(params)
        .then((res) => {
          console.log(res);
          this.sceneLinkage.sceneList = res.data.result;
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    getGatewayList() {
      devicesAll6200Mac()
        .then((res) => {
          console.log(res.data.result);
          this.sceneLinkage.GatewayList = res.data.result;
          let str = this.sceneLinkage.GatewayList;
          for (let i in str) {
            let devNickname = str[i].devNickname?str[i].devNickname:'';
            let hsAddCommunity = str[i].hsAddCommunity?str[i].hsAddCommunity:'';
            let hsAddDoorplateno = str[i].hsAddDoorplateno?str[i].hsAddDoorplateno:'';
            let hsAddFloor = str[i].hsAddFloor?str[i].hsAddFloor:'';
            let devAuthSecret = str[i].devAuthSecret?str[i].devAuthSecret:'';
            
            str[i].label = devNickname + `\u3000` + devAuthSecret + `\u3000` + hsAddCommunity + `\u3000` + hsAddDoorplateno + `\u3000` + hsAddFloor ;
          }
          this.sceneLinkage.GatewayList
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    addSceneName() {
      if (this.sceneKindPipe.sceneName == '') {
        this.$message({showClose: true,message: '场景名称不能为空',type: 'warning',});
        return
      }
        this.sceneKindPipe.loading = true;
        let params = {
          name: this.sceneKindPipe.sceneName,
          photo: this.sceneKindPipe.imgUrl,
          isPublic: true,
        };
        sceneCategoryAdd(params)
          .then((res) => {
            this.getAllSceneCategory();
            this.sceneKindPipe.loading = false;
            console.log(res);
            this.$message({
              showClose: true,
              message: '添加成功',
              type: 'success',
            });
          })
          .catch((err) => {
            this.sceneKindPipe.loading = false;
            this.isError(err, this);
          });
    },
    getStandLinkageList() {
      standLinkageList(this.scenarioEditor.rowDatas.jsHouseId)
        .then((res) => {
          console.log(res);
          this.sceneLinkage.tableList = res.data.result;
          if (this.sceneLinkage.tableList) {
            this.sceneLinkage.isShow = true;
          }
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    seleImg(item,imgUrl,index){
      let allIconName = []
      
      for(let j of this.sceneKindPipe.imgList){
        allIconName.push(j.iconName)
      }
      console.log(this.sceneKindPipe.sceneName=='','111')
      console.log(allIconName.indexOf(this.sceneKindPipe.sceneName)==-1)
      if(this.sceneKindPipe.sceneName==''||allIconName.indexOf(this.sceneKindPipe.sceneName)>-1){ //判断用户是否手动添加场景名称 默认为选择图标的名称
        this.sceneKindPipe.sceneName = item.iconName
      }
      console.log(this.sceneKindPipe.imgList)
      for(let i in this.sceneKindPipe.imgList){
        console.log( i , index );
        if(i == index){
          this.sceneKindPipe.imgUrl=imgUrl;
          console.log(this.sceneKindPipe.imgList[i])
           this.sceneKindPipe.imgList[i].toggleImgClass=true;
        }else{
          console.log(this.sceneKindPipe.imgList[i])
           this.sceneKindPipe.imgList[i].toggleImgClass=false;
        }
      }
    },
    imageMagnification(imgUrl){
      this.sceneKindPipe.imgUrl=imgUrl;
      this.sceneKindPipe.currentImg=true;
    },
    closeImg(){
      this.sceneKindPipe.imgUrl='';
      this.sceneKindPipe.sceneName==''
      this.sceneKindPipe.currentImg=false;
    },
  },

};
</script>
<style lang="scss" scoped>

.addSceneDialog-row{
  padding:30px 10px;
  height:50vh;
  overflow-y:auto;
  .imgBox{
    margin:10px;
    padding:10px;
    border:1px #999 solid;
    display: inline-block;
    transition: all 0.1s;
    cursor:pointer;
    img{
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-drag: none;
      user-select: none;
      height:100px;
      width:100px;
    }
  }
  .seleCurrentImg{
    border-color:green;
    transform: scale(1.1);
  }
  .imgBox:hover{
    transform: scale(1.1);
  }
  .mask {
	  width:100%;
	  height:1200px;
	  background:gray;
	  position:fixed;
	  top:0;
	  left:0;
	  z-index:100;
	  opacity:0.6;
  }
  .current-img {
  	position:fixed;
  	top:50%;
  	left:50%;
  	transform:translateX(-50%) translateY(-50%);
  	z-index:1000;
  	overflow:hidden;
  }
  .close-img {  
  	position:absolute;
  	top:10px;
  	right:16px;
  	cursor:pointer;
  	color:#999;
    font-size:24px;
  }
}
.theDeposit {
  p {
    margin: 2px 0;
    display: inline-block;
  }
  span {
    color: blue;
  }
}

.block {
  text-align: center;
}
.carouseHeight{
  height:40vh
}
</style>